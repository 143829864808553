import React, { createContext, useState } from "react";
export const OrgContext = createContext({
  organizationId : ""
});

const OrgProvider = ({ children }) => {
    const [organizationId, setOrganizationId] = useState("");
    return (
      <OrgContext.Provider value={{ organizationId, setOrganizationId }}>
        {children}
      </OrgContext.Provider>
    );
  };

  // const withOrg = (Child) => (props) => (
  //   <OrgContext.Consumer>
  //     {(context) => <Child {...props} {...context} />}
  //   </OrgContext.Consumer>
  // );

  export default OrgProvider;