import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  faEnvelope,
  faEnvelopeOpen,
  faCircleExclamation,
  faBellSlash,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { List } from "antd";

import PageSnipper from "../../components/PageSnipper";
import { getReqParamheader } from "../../services/apiCall";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import StatisticsWidget from "../../components/StatWidget";
import BaseBackButton from "../../components/BaseBackButton";

export default function EmailStats() {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const { EmailId } = useParams();

  useEffect(() => {
    getEmailStats(EmailId);
  }, [EmailId]);

  const getEmailStats = (emailId) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/viewstats/${emailId}`;
    getReqParamheader(url)
      .then((res) => {
        setLoading(false);
        setStats(res?.data?.data);
        console.log("Response : ", res?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return loading ? (
    <PageSnipper loading={loading} />
  ) : (
    <div className="px-6">
      <div className="mb-5 flex justify-start items-center gap-2">
        <BaseBackButton />
        <p className="text-sm md:text-lg lg:text-xl font-medium">
          Email Stats :{" "}
          <span className="font-semibold text-gray-700 underline">
            {stats.Subject}
          </span>
        </p>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 w-full gap-6 my-2">
        <StatisticsWidget
          label="Delivered"
          icon={faEnvelope}
          count={stats.Delivered_Count}
        />
        <StatisticsWidget
          label="Opened"
          icon={faEnvelopeOpen}
          count={stats.Opened_Count}
        />
        <StatisticsWidget
          label="Clicked"
          icon={faEnvelopeOpen}
          count={stats.Clicked_Count}
        />

        <StatisticsWidget
          label="Deferred"
          icon={faClock}
          count={stats.Deferred_Count}
        />
        <StatisticsWidget
          label="Bounced"
          icon={faCircleExclamation}
          count={stats.Bounced_Count}
        />
        <StatisticsWidget
          label="Unsubscribe"
          icon={faBellSlash}
          count={stats.Unsubscribe_Count}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full gap-6 mt-8">
        {stats?.Bounced_Emails?.length > 0 && (
          <div className="text-lg font-bold p-2 bg-white rounded-md">
            Bounced Email ids ({stats?.Bounced_Emails?.length})
            <div className="py-4 h-[20rem] overflow-y-auto relative rounded-sm border border-gray-200 scroll-smooth">
              <List
                size="small"
                bordered
                dataSource={stats.Bounced_Emails}
                renderItem={(item) => (
                  <List.Item className="bg-white">{item}</List.Item>
                )}
              />
            </div>
          </div>
        )}

        {stats?.Unsubscribe_Emails?.length > 0 && (
          <div className="text-lg font-bold p-2 bg-white rounded-t-md">
            Unsubscribed ids({stats.Unsubscribe_Emails?.length})
            <div className="py-4 h-[20rem] pb-10 overflow-y-auto relative">
              <List
                size="small"
                bordered
                dataSource={stats.Unsubscribe_Emails}
                renderItem={(item) => (
                  <List.Item className="bg-white">{item}</List.Item>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
