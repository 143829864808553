import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseTable from "../../components/BaseTable";
import BaseBackButton from "../../components/BaseBackButton";
import { getItem } from "../../utils/storage";
import {
  getReqParamheader,
  postReqParamheader,
  deleteReqParamheader,
  patchReqParam,
} from "../../services/apiCall";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../components/Modal";
import PageSnipper from "../../components/PageSnipper";

function CustomContacts() {
  const [list, setList] = useState([]);
  const [acticvelist, setActiveList] = useState({});
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const [showContact, setShowContact] = useState(false);
  const [loading, setLoading] = useState(false);
  const path = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
  }, []);

  useEffect(() => {
    if (orgId && userId) {
      getContact();
    }
  }, [orgId]);

  const getContact = () => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/customlist_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get custom contact details response", res?.data);
          setLoading(false);
          setList(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };

  const handleDelete = (id) => {
    let a = window.confirm(
      "Are you sure you want to delete this custom contact?"
    );
    if (a) {
      handleRemove(id);
    }
  };
  const handleEdit = (id) => {
    navigate(`${path}/${id}`);
  };

  const handleRemove = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/deletecustomlist/${id}/${orgId}/${userId}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("delete custom response", res?.data);
          setLoading(false);
          setTimeout(() => {
            getContact();
            toast.success(res?.data?.message);
          }, 1000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  const handlRestore = (id) => {
    handleRestore(id);
  };
  const handleRestore = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/restorecustomlist/${id}/${orgId}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("restore article response", res?.data);
          setLoading(false);
          setTimeout(() => {
            toast.success(res?.data?.message);
            getContact();
          }, 1000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  const handleView = (id) => {
    //
    fetchCustomContactDetails(id);
  };
  const fetchCustomContactDetails = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/customlist_detailsbyId/${id}/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get custom contact by id response", res?.data);
          let data = res?.data?.data?.Members;
          setLoading(false);
          setActiveList(data);
          setShowContact(true);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between mb-3">
            <div className="flex items-center">
              <BaseBackButton />
              <span className="text-2xl ml-2">
                Custom Lists ({list.length})
              </span>
            </div>
            <p className="text-small text-blue-500 mt-2 cursor-pointer mb-4">
              <Link to={`${path}/create`}>Create a Custom List</Link>
            </p>
          </div>
          {list?.length > 0 ? (
            <div>
              <BaseTable
                header={["Name", "Contact Count", "Last Accessed", "Actions"]}
                data={list}
                remove={handleDelete}
                edit={handleEdit}
                restore={handlRestore}
                view={handleView}
                type="custom"
              />
            </div>
          ) : (
            <div className="w-full text-center">No Custom Contacts found</div>
          )}
          {showContact && (
            <Modal setOpenModal={setShowContact} title="View Contact List">
              {acticvelist && acticvelist.length > 0 ? (
                <div>
                  {acticvelist.map((item, i) => (
                    <div key={i}>
                      {console.log(item)}
                      <div className="flex text-sm text-gray-600">
                        <ul>
                          <li>
                            <p> First Name: {item?.FirstName} </p>
                            <p> Email: {item?.Email} </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <p className="text-sm text-gray-600"> No Contact Found</p>
                </div>
              )}
            </Modal>
          )}

          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default CustomContacts;
