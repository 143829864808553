import React from 'react'

function DefaultButton({ bg, txt, align, link,btnSize }) {

    return (
        <div width="100%" style={{ width: '100%', textAlign: `${align}` ,marginTop:'12px'}}>
            <table
                    width="600"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                >
                    <tbody>
                    <tr>
                        <td align={align}>
                        <table
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                        >
                            <tbody>
                            <tr>
                                <td
                                align="center"
                                bgcolor={bg}
                                style={{ padding: '8px 16px 8px 16px', background: `${bg}`, borderRadius:'2px', textAlign: "center" }}
                                >
                                    <a href={link? link :null} target='_blank' width="100%" style={{textDecoration: "none"}}>
                                        <span className="buttonClass" bgcolor={bg} style={{ background: `${bg}`,fontSize:`${btnSize}`, color: 'white', border: 'none' ,borderRadius:'2px', display: 'block'}}>
                                            {txt}
                                        </span>
                                    </a>

                                    </td>
                            </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
        </div>
    )
}

export default DefaultButton
