import React from 'react'
import ResponsivePagination from 'react-responsive-pagination';

const Pagination = ({total, current, onPageChange}) => {
  return (
    <ResponsivePagination
      total={total}
      current = {current}
      onPageChange = {onPageChange}
    />
  )
}

export default Pagination