import React, { useState, useEffect } from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'
import { getItem } from '../../utils/storage'
import PageSnipper from '../../components/PageSnipper';

function CreateEventAirtable() {
    const [msg, setMsg] = useState('Valid Organization')
    const [airtableSrc, setAirtableSrc] = useState('');
    const [loading, setLoading] = useState(true);

    // const path = useLocation().pathname
    // const navigate = useNavigate()

    useEffect(() => {
        let orgid = getItem('currentOrg');
        if(orgid.length > 0){
          invokeAirtable(orgid);
        }else{
          setMsg("Invalid Organization");
        }
    }, [])

    const invokeAirtable = (org) =>
    {
      let current = encodeURIComponent(org);
      let source = "https://airtable.com/embed/shrbfVge9jGtJRWDo?prefill_Event+Entry=Smorgborg+URL&hide_Event%20Entry=true&prefill_Event%20Organizer="+current + "&hide_Event+Organizer=true";
      setLoading(false);
      setAirtableSrc(source);
    }

    return (
        <>
            <div className='px-4 w-full flex justify-center items-center flex-col'>
              { airtableSrc.length > 0 ?
                <iframe class="airtable-embed" src={airtableSrc} frameborder="0" width="80%" height="1000"></iframe>
              : <div>msg</div> }
            </div>
            <PageSnipper loading={loading} />
        </>
    )
}

export default CreateEventAirtable
