import React from "react";

export default function HomeCreateNew({
  children,
  buttonText,
  handleClicked,
  secondaryButtonText,
  secondaryButtonClicked,
}) {
  return (
    <div className="flex flex-col justify-center items-center py-20 ">
      <div className="flex-row py-4 text-center">{children}</div>
      <div className="flex-row">
        <button
          className="border rounded-md  bg-blue-600 text-white px-4 py-2 hover:bg-blue-400"
          onClick={handleClicked}
        >
          {buttonText}
        </button>
        {secondaryButtonText ? (
          <button
            className="border rounded-md bg-gray-200 text-gray-700 px-4 py-2 ml-3 hover:bg-gray-300"
            onClick={secondaryButtonClicked}
          >
            {secondaryButtonText}
          </button>
        ) : null}
      </div>
    </div>
  );
}
