import React, { useState, useEffect, memo } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { putReqParamheaderFile, getReqParamheader, postReqParamheaderFile } from '../services/apiCall'
import { useNavigate } from "react-router-dom";
import { getItem, setItem } from '../utils/storage';
import { API_URL_CONSTANT } from '../constants/apiConstant';
import TextEditter from '../components/TextEditter';
import PageSnipper from '../components/PageSnipper';
import Multiselect from 'multiselect-react-dropdown';
import Modal from '../components/Modal'

function UpdateOrg({ orgId, data, setIsDashboard }) {
    const [orgName, setOrgName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [content, setContent] = useState('')
    const [state, setState] = useState('')
    const [loading, setLoading] = useState(false);
    const [city, setCity] = useState('')
    const [pin, setPin] = useState('')
    const [vEmail, setVEmail] = useState({ msg: '', v: false })
    const [imgfile, uploadimg] = useState({})
    const [img, setImg] = useState(false)
    const [collection, setCollection] = useState([])
    const [selectedcollection, setSelectedCollection] = useState([])
    const [preSelected, setPreSelected] = useState([]);
    const navigate = useNavigate();
    const [description, setDescription] = useState('')
    const [website, setWebsite] = useState('')

    const [showModal, setShowModal] = useState(false);
    const [albumList, setAlbumList] = useState([])
    const [unsorted, setUnsorted] = useState([])
    const [show, setShow] = useState(false);
    const [activeAlbum, setActiveAlbum] = useState(
        {
            name: 'unsorted',
            data: []
        }
    )

    useEffect(() => {
        if (data) {
            setOrgName(data?.OrganizationName)
            setDescription(data?.Organization_Description)
            setEmail(data?.primary_email)
            setAddress(data?.address?.Street)
            setState(data?.address?.State)
            setCity(data?.address?.City)
            setPin(data?.address?.Pincode)
            setContent(data?.email_Footer)
            setWebsite(data?.Website_link)
            /*if (data?.logo) {
                uploadimg({
                    picturePreview: data?.logo,
                    pictureAsFile: data?.logo,
                })
                setImg(!img)
            }*/
            if(data?.logo){
              uploadimg(data?.logo)
              setImg(true)
            }
            getCollections()
            setTimeout(function(){
                let collectdata = [];
                let selectionData = [];
                if(data.collections){
                    for(let k=0;k<data.collections.length;k++){
                        collectdata.push({name: data.collections[k], id: k})
                        selectionData.push(data.collections[k]);
                    }
                }
                if(selectionData && selectionData.length > 0){
                    setSelectedCollection(selectionData);
                }
                setPreSelected(collectdata);
            }, 500);
            fetchPhotos()
            albumDetails()
        }

    }, [data])

    const handleUpload = () => {
        setShowModal(!showModal)
    }
    const handleUploadPhoto = () => {
        setShow(!show)
    }
    const handleContent = (txt) => {
        setContent(txt)
    }
    const handleDescription = (txt) => {
        setDescription(txt)
    }
    const handleselect = (val) => {
        if (val === 'unsorted') {
            console.log(unsorted)
            setActiveAlbum({ ...activeAlbum, name: 'unsorted', data: unsorted })
        }
        else {
            let data = albumList.filter((item) => (
                item?.Album_Name === val
            ))
            console.log(data)
            setActiveAlbum({ ...activeAlbum, name: data[0]?.Album_Name, data: data[0]?.photoslist })
        }

    }
    const imgFilehandler = (e) => {
        if (e.target.files.length !== 0) {
            uploadimg({
                picturePreview: URL.createObjectURL(e.target.files[0]),
                pictureAsFile: e.target.files[0],
            })
        }
    }
    const handleUploadSave = () => {
        setShow(false)
        if (imgfile) {
            let data = {}
            data.files = imgfile?.pictureAsFile
            uploadPhoto(data)
        }
    }
    const albumDetails = () => {
      let user = getItem('user_id');
        let url = `${API_URL_CONSTANT.baseUrl}/albumdetails/${orgId}/${user}`
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                    console.log('get all album response', res?.data)
                    setAlbumList(res?.data?.data)
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
                toast.error(err?.response?.data?.message)
            })
    }
    const fetchPhotos = () => {
        let user = getItem('user_id');
        let url = `${API_URL_CONSTANT.baseUrl}/unsortedPhotos/${orgId}/${user}`
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                    console.log('get all photos response', res?.data)
                    setUnsorted(res?.data?.data)
                    let photosdata = res?.data?.data
                    let orglogopath = getItem("currentOrgLogo")
                    let orglogo = {
                        "_id": "999",
                        "tags": [],
                        "Photo_Path": orglogopath,
                        "PhotoAlbumId": "",
                        "orgId": orgId,
                        "deleted": false,
                        "deletedAt": null,
                        "deletedById": null,
                        "lastAccessedAt": {
                            "userId": "",
                            "time": ""
                        },
                        "modifiedById": "",
                        "modifiedAt": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": 0
                    };
                    photosdata.push(orglogo)
                    setActiveAlbum({ ...activeAlbum, name: 'unsorted', data: photosdata })
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
                toast.error(err?.response?.data?.message)
            })
    }

    const uploadPhoto = (data) => {
        setLoading(true)
        let user = getItem('user_id');
        let url = `${API_URL_CONSTANT.baseUrl}/uploadPhotos/${orgId}/${user}`
        postReqParamheaderFile(url, data)
            .then((res) => {
                if (res?.status) {
                    console.log('upload photo response', res?.data)
                    uploadimg({})
                    fetchPhotos()
                    setLoading(false)
                    setTimeout(()=>{
                        toast.success(res?.data?.message)
                    },1000)
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
                setLoading(false)
                setTimeout(()=>{
                    toast.error(err?.response?.data?.message)
                },1000)
            })
    }

    const handleSubmit = (e) => {
        const id = getItem('user_id')
        e.preventDefault()
        let data = {
            // OrganizationName: orgName,
            // primary_email: email,
            Organization_Description: description,
            Street: address,
            City: city,
            State: state,
            Pincode: pin,
            email_Footer: content,
            Website_link: website
            // alternative_address:'fghf'
        }
        if (imgfile) {
            data.logo = imgfile
        }
        // if (imgfile?.pictureAsFile) {
        //     data.logo = imgfile?.pictureAsFile || ''
        // }

        data.collections = selectedcollection.join(',');

               setLoading(true);


        if (id) {
            updateOrg(data, id)
        }

    }
    const emailValidation = (val) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(val)) {
            setVEmail({ ...vEmail, v: true, msg: 'Please provide a valid email address' })
            return false;
        }
        else {
            setVEmail({ ...vEmail, v: false, msg: '' })
            return true
        }

    }
    const updateOrg = (data, id) => {
        setLoading(true)
        let url = `${API_URL_CONSTANT.baseUrl}/Update_orgdetails/${orgId}/${id}`
        putReqParamheaderFile(url, data)
            .then((res) => {
                if (res?.status) {
                    console.log('response update org', res?.data?.data)
                    setLoading(false)

                    /*setTimeout(()=>{
                        toast.success('Updated Org Success ..!')
                    },1000); */

                    //Trigger refresh here
                    window.location.reload();
                    /*
                    setTimeout(() => {
                        getUserData()
                        setIsDashboard(true)
                    }, 2000)
                    */
                }
                else {
                    console.log('response err', res?.message)
                    // toast.error('Org already exist...!')
                }
            })
            .catch(err => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error(err?.response?.data?.message)
                }, 1000)

            })

    }
    const getUserData = () => {
        const id = getItem('user_id')
        if (id) {
            getUserById(id)
        }
    }
    const getUserById = (id) => {
        let url = `${API_URL_CONSTANT.baseUrl}/user/${id}`
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                    console.log('response get user by id ', res?.data?.data)
                    // setItem('user', JSON.stringify(res?.data?.data))
                }
                else {
                    console.log('response err', res?.message)
                    // toast.error(`${res?.message}`)
                }
            })
            .catch(err => {
                console.log('err user by id ', err)
                toast.error('Something went wrong !')
            })
    }

    const onSelectCollection = (selectedList, selectedItem) => {
      let addSelected = selectedcollection;
      addSelected.push(selectedItem.name);
      setSelectedCollection(addSelected);
    //   console.log(addSelected);
    }

    const onRemoveCollection = (selectedList, removedItem) => {
      let index = -1;
      let removeSelected = selectedcollection;
      for(let i=0;i<removeSelected.length;i++){
        if(removedItem.name === removeSelected[i]){
            index = i;
            break;
        }
      }
      if (index > -1) { // only splice array when item is found
        removeSelected.splice(index, 1); // 2nd parameter means remove one item only
      }
      setSelectedCollection(removeSelected);
      console.log(removeSelected);
    }

    const getCollections = async(id) => {
        let url = `${API_URL_CONSTANT.baseUrl}/collection_list`
        const res = await getReqParamheader(url);
            // .then((res) => {
        if (res?.status) {
            let count = 0;
            let collectiondata = res?.data?.data.reduce(function(ids, obj){
                if(obj.status === "Customer"){
                    ids.push({name: obj.collectionName, id: count});
                    count++;
                }
                return ids;
            }, []);
            // console.log('collection data -', collectiondata)
            collectiondata.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            setCollection(collectiondata);
            // setItem('user', JSON.stringify(res?.data?.data))
        }
        else {
            console.log('response err', res?.message)
            toast.error('Something went wrong !')
        }
            // })
            // .catch(err => {
            //     console.log('err user by id ', err)
            //     toast.error('Something went wrong !')
            // })
    }


    return (
        <>
            {
                loading ?
                    <PageSnipper loading={loading} />
                    :
                    <div className='w-full  bg-gray-100 py-8'>
                        <div className='w-[60%] mx-auto'>
                            <p className='text-bold text-2xl mb-3'> Edit Organization </p>
                            <form onSubmit={handleSubmit} >
                                <div className='my-5'>
                                    <p>Organization Name*: </p>
                                    <input type='text' placeholder='Enter Org Name'
                                        className='px-3 py-1 bg-ggray-200 w-full my-3 border rounded-sm'
                                        value={orgName}
                                        readOnly
                                        disabled
                                        onChange={(e) => setOrgName(e.target.value)}
                                    />
                                    <p>Primary Email*: </p>
                                    <input type='email' placeholder='Enter Primary Email'
                                        className='px-3 py-1 bg-gray-200 w-full my-3 border rounded-sm'
                                        value={email}
                                        readOnly
                                        disabled
                                        // required
                                        onChange={(e) => {
                                            emailValidation(e.target.value)
                                            setEmail(e.target.value)
                                        }}
                                    />
                                    <p>Organization Description*: </p>
                                    <div className='my-3 w-[580px]'>
                                        <TextEditter txt={description} handleTxt={handleDescription} />
                                    </div>
                                    <p className={vEmail?.v ? 'block text-red-700 font-light' : "hidden"}>

                                        {
                                            vEmail?.msg
                                        }

                                    </p>
                                    <p>Street Address*: </p>
                                    <input type='text' placeholder='Enter Address'
                                        className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        required
                                    />
                                    <div className='grid grid-cols-3 gap-4 mb-2'>

                                        <div>
                                            <p>City: </p>
                                            <input type='text' placeholder='Enter City'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <p>State: </p>
                                            <input type='text' placeholder='Enter State'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                                //required
                                            />
                                        </div>
                                        <div>
                                            <p>Zip Code: </p>
                                            <input type='text' placeholder='Enter Zip code'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                value={pin}
                                                onChange={(e) => setPin(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <p>Website: </p>
                                            <input type='text' placeholder='Enter Website'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                style={{width: "350px"}}
                                                value={website}
                                                onChange={(e) => setWebsite(e.target.value)}
                                            />
                                        </div>

                                    </div>

                                    <label>Logo (Suggested Size: 600x600)* :</label>
                                    <div className='border rounded-sm bg-white py-4 my-3'>
                                        {
                                          !img ?
                                              <div>
                                                  <div className='flex justify-center '>
                                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                          strokeWidth={1.5} stroke="currentColor" className="w-100 h-40">
                                                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                      </svg>
                                                  </div>
                                                  <p className='text-small text-blue-600 cursor-pointer text-center' onClick={handleUpload}> Choose a feature Photo</p>
                                              </div>
                                              :
                                              <div className='flex justify-center flex-col items-center'>
                                                  <img src={imgfile} className='max-h-[400px] max-w-[400px]' alt="med1" />
                                                  <p className='text-small text-blue-600 cursor-pointer text-center mt-3' onClick={() => {
                                                      setImg(!img)
                                                      uploadimg('')
                                                  }}> Remove and Choose New</p>
                                              </div>
                                        }
                                    </div>
                                    <div className='mt-2'>
                                        <label>Footer description :</label>
                                        <div className='my-3 w-[460px]'>

                                            <TextEditter txt={content} handleTxt={handleContent} />
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                    <label>Choose organizations from where you want to see events:</label>
                                    { collection?.length > 0 ?
                                    <Multiselect
                                      options={collection} // Options to display in the dropdown
                                      displayValue="name" // Property name to display in the dropdown options
                                      onSelect={onSelectCollection} // Function will trigger on select event
                                      onRemove={onRemoveCollection} // Function will trigger on remove event
                                      selectedValues={preSelected}
                                      /> : <div></div> }
                                    </div>
                                </div >
                                <div className='mt-8 text-end'>
                                    <button className='px-4 py-2 bg-blue-400 hover:bg-blue-700 border rounded-md' type='submit' >Submit</button>
                                </div>
                            </form >

                            {
                                showModal &&

                                <Modal
                                    setOpenModal={setShowModal}
                                    title='Select Photo'
                                >

                                    <select className=" border border-gray-300 w-full py-2 mt-3
                                 text-gray-700 text-sm rounded-sm  px-4 bg-white"
                                        //
                                        onChange={(e) => handleselect(e.target.value)}
                                    >
                                        <option value='unsorted'> Unsorted</option>
                                        {
                                            albumList?.length > 0 && albumList.map((item, i) => (
                                                <option value={item?.Album_Name} key={i}>{item?.Album_Name}</option>
                                            ))
                                        }
                                    </select>
                                    <div className='my-2'>
                                        <span
                                            className='text-small text-blue-500 cursor-pointer'
                                            onClick={handleUploadPhoto}
                                        >
                                            Upload Photos
                                        </span>
                                    </div>
                                    {
                                        loading ?
                                            <PageSnipper loading={loading} />
                                            :
                                            <div className='grid grid-cols-4 gap-4 mt-4'>
                                                {
                                                    activeAlbum && activeAlbum?.data?.map((item, i) => (
                                                        <div key={i}>

                                                            {

                                                                activeAlbum?.name === 'unsorted' ?
                                                                    <div
                                                                        onClick={() => {
                                                                            uploadimg(item?.Photo_Path)
                                                                            setImg(!img)
                                                                            setShowModal(false)
                                                                        }}
                                                                        className='cursor-pointer h-[100px] w-[100px]'>
                                                                        <img src={item?.Photo_Path} style={{height: "inherit"}} alt='photo path missing' />
                                                                    </div>

                                                                    :
                                                                    <div
                                                                        onClick={() => {
                                                                            uploadimg(item?.Photo_Path)
                                                                            setImg(!img)
                                                                            setShowModal(false)
                                                                        }}
                                                                        className='cursor-pointer h-[100px] w-[100px]'>
                                                                        <img src={item?.Photo_Path} style={{height: "inherit"}} alt='photo path missing' />
                                                                    </div>

                                                            }
                                                        </div>

                                                    ))
                                                }

                                            </div>
                                    }

                                </Modal>
                            }
                            {
                                show &&
                                <Modal
                                    setOpenModal={setShow}
                                    title='Upload Photo'
                                >

                                    <label className='text-gray-600'> Select Photo:</label>
                                    {
                                        imgfile?.picturePreview ?
                                            <div className='w-full flex justify-center flex-col'>
                                                <img src={imgfile?.picturePreview} alt='photo' className='max-h-[400px] max-w-[400px]' />
                                                <p
                                                    className='mt-3 cursor-pointer text-blue-500'
                                                    onClick={() => {
                                                        uploadimg({})
                                                    }}> Remove and Choose new</p>
                                            </div>
                                            :
                                            <input
                                                type='file'
                                                className='px-4 py-1 my-2 w-full border'
                                                accept=".jpg,.jpeg,.png"
                                                onChange={imgFilehandler}
                                            />
                                    }

                                    <div className='flex justify-end'>
                                        <button
                                            className='px-4 py-2 bg-blue-500 rounded-sm my-3  text-white hover:bg-blue-700'
                                            onClick={activeAlbum?.name === 'unsorted' ? handleUploadSave : null}

                                        >
                                            Save Photo
                                        </button>
                                    </div>
                                </Modal>
                            }

                            <ToastContainer />
                        </div >
                    </div >
            }
        </>

    )
}

export default memo(UpdateOrg)
