import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { API_URL_CONSTANT } from '../constants/apiConstant'
import { getReqParamheader } from '../services/apiCall'

function Unsub() {
    const [orgId, setOrg] = useState('')
    const [cId, setC] = useState('')
    const [userId, setUserId] = useState('')
    const path = useLocation().pathname
    const [isUn, setUn] = useState(false)

    useEffect(() => {
        let orgid = path.split('/')[2]
        let userid = path.split('/')[3]
        setOrg(orgid)
        setUserId(userid)
    }, [])
    useEffect(() => {
        if (userId && orgId) {
            getContact()
        }
    }, [userId])

    const getUnsub = () => {
        let url = `${API_URL_CONSTANT.baseUrl}/email/unsubscribe/${orgId}/${cId}`
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                    console.log('get contact details response', res?.data)
                     
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
            })
    }
    const getContact = () => {
        let url = `${API_URL_CONSTANT.baseUrl}/contact_details/${orgId}/${userId}`
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                    console.log('get contact details response', res?.data)
                    
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    return (
        <div className='w-full flex justify-center items-center'>
            <div className='w-[30%] border rounded-md bg-gray-100 px-8 py-3 text-gray-500'>
                {
                   ! isUn ?

                        <div>
                            <p className='text-2xl'> Unsubscribe Successful!  </p>
                            <p className='text-sm mt-4'>
                                Sorry to see you go! Your email address
                                has been removed from San Diego Visual Arts Network's contact list.
                                If this was a mistake, you can  your email to the list.
                            </p>
                        </div>
                        :
                        <div> 
                            fetching data for Unsubscribe .......!
                        </div>

                }



            </div>

        </div>
    )
}

export default Unsub
