import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ROUTER_URL_CONSTANT } from "./constants/routerUrlConstant";
import { getItem } from "./utils/storage";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import CreateOrg from "./pages/CreateOrg";
import ProfileSetting from "./pages/ProfileSetting";
import ModuleLayout from "./pages/ModuleLayout";
import EmailHome from "./pages/EmailHome";
import ContactHome from "./pages/ContactHome";
import ArticleHome from "./pages/ArticleHome";
import CreateArticle from "./pages/article/CreateArticle";
import TeamHome from "./pages/TeamHome";
import PhotoHome from "./pages/photo/PhotoHome";
import Dashboard from "./pages/dashboard/Dashboard";
import CreateEmail from "./pages/email/CreateEmail";
import EditMember from "./pages/team/EditMember";
import CustomContact from "./pages/contact/CustomContacts";
import CreateCustomContact from "./pages/contact/CreateCustomContact";
import View from "./pages/article/View";
import ResetPassword from "./pages/ResetPassword";
import EditProfile from "./pages/EditProfile";
import ChangePassword from "./pages/ChangePassword";
import EventsHome from "./pages/EventsHome";
import CreateEvent from "./pages/event/CreateEvent";
import Unsub from "./pages/Unsub";
import ViewEvent from "./pages/event/ViewEvent";
import ViewEmail from "./components/ViewEmail";
import CreateWidget from "./pages/widgets/CreateWidget";
import WidgetHome from "./pages/WidgetHome";
import OrgApproval from "./pages/org/OrgApproval";
import EmailViewTemplate from "./components/EmailViewTemplate";
import CreateEventAirtable from "./pages/event/CreateEventAirtable";
import ViewWidget from "./pages/widgets/View";
import EmailStats from "./pages/email/EmailStats";

const CheckAuth = () => {
  const session = getItem("token");
  if (session) {
    return true;
  }
  return false;
};

function RequireAuth({ children }) {
  let token = CheckAuth();
  let location = useLocation();
  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
}

function RootRouter() {
  return (
    <>
      <Routes>
        {/* <Route path={ROUTER_URL_CONSTANT.ResetPassword} element={<ResetPassword />} /> */}
        <Route path={ROUTER_URL_CONSTANT.Home} element={<Home />} />
        <Route path={ROUTER_URL_CONSTANT.Login} element={<Login />} />
        <Route path={ROUTER_URL_CONSTANT.Forget} element={<ResetPassword />} />
        {/* <Route
          path={ROUTER_URL_CONSTANT.ChangePassword}
          element={<ChangePassword />}
        /> */}
        <Route path={ROUTER_URL_CONSTANT.Signup} element={<Signup />} />
        <Route path={ROUTER_URL_CONSTANT.Articlev} element={<View />} />
        <Route path={ROUTER_URL_CONSTANT.Eventv} element={<ViewEvent />} />
        <Route
          path={ROUTER_URL_CONSTANT.Emailv}
          element={<EmailViewTemplate />}
        />
        <Route path={ROUTER_URL_CONSTANT.EmailUnsub} element={<Unsub />} />
        <Route path={ROUTER_URL_CONSTANT.ViewWidget} element={<ViewWidget />} />
        <Route
          path={ROUTER_URL_CONSTANT.CreateOrg}
          element={
            <RequireAuth>
              <CreateOrg />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.ProfileSetting}
          element={
            <RequireAuth>
              <ModuleLayout user={true}>
                <ProfileSetting />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.EditProfile}
          element={
            <RequireAuth>
              <ModuleLayout user={true}>
                <EditProfile />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.ChangePassword}
          element={
            <RequireAuth>
              <ModuleLayout user={true}>
                <ChangePassword />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.Manage}
          element={
            <RequireAuth>
              <ModuleLayout>
                <Dashboard />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.Email}
          element={
            <RequireAuth>
              <ModuleLayout>
                <EmailHome />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.Contact}
          element={
            <RequireAuth>
              <ModuleLayout>
                <ContactHome />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.CustomContact}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CustomContact />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.CreateCustomContact}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateCustomContact />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.EditCustomContact}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateCustomContact />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.Team}
          element={
            <RequireAuth>
              <ModuleLayout>
                <TeamHome />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.Article}
          element={
            <RequireAuth>
              <ModuleLayout>
                <ArticleHome />
              </ModuleLayout>
            </RequireAuth>
          }
        />

        <Route
          path={ROUTER_URL_CONSTANT.Photo}
          element={
            <RequireAuth>
              <ModuleLayout>
                <PhotoHome />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.CreateArticle}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateArticle />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.EditArticle}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateArticle />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.CreateEmail}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateEmail />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.EditEmail}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateEmail />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.EmailStats}
          element={
            <RequireAuth>
              <ModuleLayout>
                <EmailStats />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.EditTeam}
          element={
            <RequireAuth>
              <ModuleLayout>
                <EditMember />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.Events}
          element={
            <RequireAuth>
              <ModuleLayout>
                <EventsHome />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.CreateEvent}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateEventAirtable />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.Widget}
          element={
            <RequireAuth>
              <ModuleLayout>
                <WidgetHome />
              </ModuleLayout>
            </RequireAuth>
          }
        />

        <Route
          path={ROUTER_URL_CONSTANT.CreateWidget}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateWidget />
              </ModuleLayout>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTER_URL_CONSTANT.EditWidget}
          element={
            <RequireAuth>
              <ModuleLayout>
                <CreateWidget />
              </ModuleLayout>
            </RequireAuth>
          }
        />

        <Route
          path={ROUTER_URL_CONSTANT.OrgApproval}
          element={
            <RequireAuth>
              <ModuleLayout>
                <OrgApproval />
              </ModuleLayout>
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
}

export default RootRouter;
