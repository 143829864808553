import React, { useState, useEffect } from "react";
import { getItem } from "../utils/storage";
import { ToastContainer, toast } from "react-toastify";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { getReqParamheader, putReqParamheaderFile } from "../services/apiCall";
import { useNavigate } from "react-router-dom";
import { setItem } from "../utils/storage";
import PageSnipper from "../components/PageSnipper";
import { Button } from "antd";

function EditProfile() {
  const [userId, setUserId] = useState("");
  const [data, setData] = useState({});
  const [imgfile, uploadimg] = useState({});
  const [img, setImg] = useState(false);
  const [fName, setFName] = useState("");
  const [email, setEmail] = useState("");
  const [lName, setLName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userid = getItem("user_id");
    setUserId(userid);
  }, []);
  useEffect(() => {
    if (userId) {
      getUserById(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (data) {
      setFName(data?.firstName);
      setLName(data?.lastName);
      setEmail(data?.email);

      if (data?.profile_photo) {
        uploadimg({
          picturePreview: data?.profile_photo,
          /* this contains the file we want to send */
          pictureAsFile: data?.profile_photo,
        });
        setImg(!img);
      }
    }
  }, [data]);

  const handleUpload = (e) => {
    if (e.target.files.length !== 0) {
      uploadimg({
        picturePreview: URL.createObjectURL(e.target.files[0]),
        /* this contains the file we want to send */
        pictureAsFile: e.target.files[0],
      });
      setImg(!img);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data1 = {
      firstName: fName,
      lastName: lName,
    };
    if (imgfile?.pictureAsFile) {
      data1.files = imgfile?.pictureAsFile || "";
    }
    if (userId) {
      updateUser(data1, userId);
    }
  };

  const updateUser = (data, id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/update_profile/${id}`;
    putReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("response update user", res?.data?.data);
          setLoading(false);
          setTimeout(() => {
            toast.success("Updated User Profile Successfully ..!");
          }, 1000);

          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          console.log("response err", res?.message);
          // toast.error('Org already exist...!')
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };

  const getUserById = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/user/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("response get user by id ", res?.data?.data);
          // setItem('user', JSON.stringify(res?.data?.data))
          // setItem('currentuser',res?.data?.data?.firstName)
          setData(res?.data?.data);
        } else {
          console.log("response err", res?.message);
          // toast.error(`${res?.message}`)
        }
      })
      .catch((err) => {
        console.log("err user by id ", err);
      });
  };

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6 flex justify-center items-center">
          <div className="w-[40%] mx-auto">
            <p className="text-bold text-2xl mb-3"> Edit User Profile </p>
            <form onSubmit={handleSubmit}>
              <div className="my-5">
                <p>First Name*: </p>
                <input
                  type="text"
                  placeholder="Enter first name"
                  className="px-3 py-1 bg-ggray-200 w-full my-3 border rounded-sm"
                  value={fName}
                  onChange={(e) => setFName(e.target.value)}
                />
                <p>Last Name*: </p>
                <input
                  type="text"
                  placeholder="Enter last name"
                  className="px-3 py-1 bg-ggray-200 w-full my-3 border rounded-sm"
                  value={lName}
                  onChange={(e) => setLName(e.target.value)}
                />
                <p>Email*: </p>
                <input
                  type="email"
                  placeholder="Enter Primary Email"
                  className="px-3 py-1 bg-gray-200 w-full my-3 border rounded-sm"
                  value={email}
                  readOnly
                  disabled
                  // required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />

                <label>Upload Profile Photo :</label>
                <div className="border rounded-sm bg-white py-4 my-3">
                  {!img ? (
                    <div>
                      <div className="flex justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-100 h-40"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                          />
                        </svg>
                      </div>
                      <div className="w-full flex justify-center my-2">
                        <input
                          type="file"
                          onChange={handleUpload}
                          accept=".jpg,.jpeg,.png"
                          className=""
                        />
                      </div>
                      {/* <p className='text-small text-blue-600 cursor-pointer text-center'> Upload Organization Logo</p> */}
                    </div>
                  ) : (
                    <div className="flex justify-center flex-col items-center">
                      <img
                        src={imgfile?.picturePreview}
                        height="200"
                        width="200"
                        alt="logo"
                      />
                      <p
                        className="text-small text-blue-600 cursor-pointer text-center mt-3"
                        onClick={() => {
                          setImg(!img);
                          uploadimg({});
                        }}
                      >
                        {" "}
                        Remove and Choose New
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-8 text-end">
                <Button
                  type="text"
                  className="mr-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
                {/* <button className='px-4 py-2 bg-blue-400 hover:bg-blue-700 border rounded-md' type='submit' > Update Profile </button> */}
                <button
                  className="px-3 py-1 border rounded-md bg-blue-500 cursor-pointer hover:bg-blue-700
                          text-white"
                >
                  Update Profile
                </button>
              </div>
            </form>

            <ToastContainer />
          </div>
        </div>
      )}
    </>
  );
}

export default EditProfile;
