import React, { useEffect } from "react";

function ArticleComponent({
  data,
  id,
  hs,
  hf,
  ts,
  tf,
  type,
  logow,
  logoh,
  read,
}) {
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  let desL = htmlToReactParser.parse(data?.Content?.slice(0, 300));
  let desM = htmlToReactParser.parse(data?.Content);
  let title = htmlToReactParser.parse(data?.Title);
  let ed = htmlToReactParser.parse(data?.Event_Description);
  let ed_excerpt = htmlToReactParser.parse(
    data?.Event_Description?.slice(0, 300)
  );
  let url = `https://smorgborg.org/view/${id}`;

  const newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return (
    <>
      {type && type === "event" ? (
        <div style={{ marginTop: "8px", marginBottom: "8px", width: "100%" }}>
          <div style={{ textAlign: "justify" }}>
            <p
              style={{
                fontSize: hs,
                fontFamily: hf,
                textAlign: "justify",
              }}
            >
              <a
                href={data?.Event_Website}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                {data?.Event_Name}
              </a>
            </p>

            {logow && logoh && data?.Featured_Photo ? (
              <div style={{ textAlign: "center", margin: "0px auto" }}>
                <a
                  href={data?.Event_Website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={data?.Featured_Photo}
                    width={logow}
                    height={logoh}
                    style={{
                      maxHeight: "600px",
                      maxWidth: "600px",
                    }}
                    alt="feature"
                  />
                </a>
              </div>
            ) : data?.Featured_Photo ? (
              <div style={{ textAlign: "center" }}>
                <img
                  src={data?.Featured_Photo}
                  alt="feature"
                  style={{ maxHeight: "600px", maxWidth: "600px" }}
                />
              </div>
            ) : null}

            <div style={{ textAlign: "justify", fontSize: ts, fontFamily: tf }}>
              <p>
                {data?.Event_Start_Date.toLocaleString("en-US", newDateOptions)}
              </p>
              <p style={{ wordWrap: "break-word" }}>
                {read ? ed_excerpt : ed}
                {read && (
                  <a
                    href={data?.Event_Website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ...Read more
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: "8px",
            marginBottom: "8px",
            width: "100%",
            textAlign: "justify",
          }}
        >
          <div style={{ textAlign: "justify" }}>
            <p style={{ fontSize: hs, fontFamily: hf }}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", marginBottom: "6px" }}
              >
                {title}
              </a>
            </p>

            {logow && logoh && data?.Featured_Photo ? (
              <div style={{ textAlign: "center", margin: "0px auto" }}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={data?.Featured_Photo}
                    style={{
                      height: "auto",
                      maxHeight: "600px",
                      maxWidth: "600px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="feature"
                  />
                </a>
              </div>
            ) : data?.Featured_Photo ? (
              <div style={{ textAlign: "center" }}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={data?.Featured_Photo}
                    alt="feature"
                    style={{
                      height: "auto",
                      maxHeight: "600px",
                      maxWidth: "600px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </a>
              </div>
            ) : null}

            <div
              style={{
                textAlign: "justify",
                fontSize: ts,
                fontFamily: tf,
                maxWidth: "600px",
                padding: "0 auto",
                margin: 0,
              }}
            >
              <style>
                {`
                  figure {
                    text-align: center; /* Center the image inside the figure */
                  }
                  figure img {
                    display: inline-block;
                    margin: 0 auto; /* Centers the image horizontally */
                    max-width: 100%; /* Ensure the image doesn't overflow */
                  }
                `}
              </style>
              {read ? desL : desM}
              {read && (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  ..... Read more
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ArticleComponent;
