import React from 'react'

function Divider() {
    return (
        <div style={{ width: '100%',marginTop:'12px' }}>
            {/* <div
                style={{
                    background: 'gray',
                    height: '1px',
                }}
            /> */}
            <hr style={{ height: "2px"}}/>
        </div>
    )
}

export default Divider
