import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { postReqParamheader, getReqParamheader, postReqParamheaderFile } from '../services/apiCall'
import { useNavigate } from "react-router-dom";
import { getItem, setItem } from '../utils/storage';
import { API_URL_CONSTANT } from '../constants/apiConstant';
import PageSnipper from '../components/PageSnipper';
import TextEditter from '../components/TextEditter';

function CreateOrg() {
    const [orgName, setOrgName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [state, setState] = useState('')
    const [content, setContent] = useState('')
    const [city, setCity] = useState('')
    const [pin, setPin] = useState('')
    const [vEmail, setVEmail] = useState({ msg: '', v: false })
    const [imgfile, uploadimg] = useState({})
    const [img, setImg] = useState(false)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [description, setDescription] = useState('')
    const [website, setWebsite] = useState('')

    const handleUpload = (e) => {
        if (e.target.files.length !== 0) {
            uploadimg({
                picturePreview: URL.createObjectURL(e.target.files[0]),
                /* this contains the file we want to send */
                pictureAsFile: e.target.files[0],
            })
            setImg(!img)

        }
    }
    const handleContent = (txt) => {
        setContent(txt)
    }
    const handleDescription = (txt) => {
        setDescription(txt)
    }
    const handleSubmit = (e) => {
        const id = getItem('user_id')
        e.preventDefault()
        let data = {
            OrganizationName: orgName,
            Organization_Description: description,
            primary_email: email,
            Street: address,
            City: city,
            State: state,
            Pincode: pin,
            email_Footer: content,
            Website_link: website
        }
        if (imgfile?.pictureAsFile) {
            data.logo = imgfile?.pictureAsFile || ''
        }
        if (id) {
            createOrg(data, id)
        }

    }
    const emailValidation = (val) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(val)) {
            setVEmail({ ...vEmail, v: true, msg: 'Please provide a valid email address' })
            return false;
        }
        else {
            setVEmail({ ...vEmail, v: false, msg: '' })
            return true
        }

    }
    const createOrg = (data, id) => {
        setLoading(true)
        let url = `${API_URL_CONSTANT.baseUrl}/create_an_organization/${id}`
        postReqParamheaderFile(url, data)
            .then((res) => {
                if (res?.status) {
                    console.log('response create org', res?.data?.data)
                    getUserData()
                    setLoading(false)
                    toast.success('Created an Org Success ..!')
                    setTimeout(() => {
                        navigate("/");
                    }, 1000)

                }
                else {
                    console.log('response err', res?.message)
                    // toast.error('Org already exist...!')
                }
            })
            .catch(err => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error(err?.response?.data?.message)
                }, 1000)


            })

    }
    const getUserData = () => {
        const id = getItem('user_id')
        if (id) {
            getUserById(id)
        }
    }
    const getUserById = (id) => {
        let url = `${API_URL_CONSTANT.baseUrl}/user/${id}`
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                    console.log('response get user by id ', res?.data?.data)
                    setItem('user', JSON.stringify(res?.data?.data))
                }
                else {
                    console.log('response err', res?.message)
                    // toast.error(`${res?.message}`) 
                }
            })
            .catch(err => {
                console.log('err user by id ', err)
                toast.error('Something went wrong !')
            })

    }


    return (
        <>
            {
                loading ?
                    <PageSnipper loading={loading} />
                    :


                    <div className='w-full  bg-gray-100 py-8'>
                        <div className='w-[60%] mx-auto'>
                            <p className='text-bold text-2xl mb-3'> Step 1: Organization Name (Required)</p>
                            <p className='text-small mb-3 text-gray-700'> This is the name of your organization. If you're just getting started,
                                don't worry too much as you can always change it later!
                            </p>
                            <form onSubmit={handleSubmit} >
                                <div className='my-5'>
                                    <p>Organization Name*: </p>
                                    <input type='text' placeholder='Enter Org Name'
                                        className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                        value={orgName}
                                        required
                                        onChange={(e) => setOrgName(e.target.value)}
                                    />
                                    <p>Organization Description*: </p>
                                    <div className='my-3 w-[580px]'>         
                                        <TextEditter txt={description} handleTxt={handleDescription} />
                                    </div>
                                    <p>Primary Email*: </p>
                                    <input type='email' placeholder='Enter Primary Email'
                                        className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                        value={email}
                                        required
                                        onChange={(e) => {
                                            emailValidation(e.target.value)
                                            setEmail(e.target.value)
                                        }}
                                    />
                                    <p className={vEmail?.v ? 'block text-red-700 font-light' : "hidden"}>

                                        {
                                            vEmail?.msg
                                        }

                                    </p>
                                    <p>Street Address*: </p>
                                    <input type='text' placeholder='Enter Address'
                                        className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        required
                                    />
                                    <div className='grid grid-cols-3 gap-4 mb-2'>
                                        <div>
                                            <p>City: </p>
                                            <input type='text' placeholder='Enter City'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <p>State: </p>
                                            <input type='text' placeholder='Enter State'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <p>Zip Code: </p>
                                            <input type='text' placeholder='Enter Zip code'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                value={pin}
                                                onChange={(e) => setPin(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <p>Website: </p>
                                            <input type='text' placeholder='Enter Website'
                                                className='px-3 py-1 bg-white w-full my-3 border rounded-sm'
                                                style={{width: "350px"}}
                                                value={website}
                                                onChange={(e) => setWebsite(e.target.value)}
                                            />
                                        </div>

                                    </div>

                                    <label> Logo (Suggested Size: 600x600)* :</label>
                                    <div className='border rounded-sm bg-white py-4 my-3'>
                                        {
                                            !img ?
                                                <div>
                                                    <div className='flex justify-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                            strokeWidth={1.5} stroke="currentColor" className="w-100 h-40">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                        </svg>
                                                    </div>
                                                    <div className='w-full flex justify-center my-2'>
                                                        <input type='file' onChange={handleUpload} accept=".jpg,.jpeg,.png" className='' />
                                                    </div>
                                                    {/* <p className='text-small text-blue-600 cursor-pointer text-center'> Upload Organization Logo</p> */}
                                                </div>
                                                :
                                                <div className='flex justify-center flex-col items-center'>
                                                    <img src={imgfile?.picturePreview} style={{maxWidth: "600px", height: "auto"}} alt="logo" />
                                                    <p className='text-small text-blue-600 cursor-pointer text-center mt-3' onClick={() => {
                                                        setImg(!img)
                                                        uploadimg({})
                                                    }}> Remove and Choose New</p>
                                                </div>
                                        }
                                    </div>
                                    <div className='mt-2'>
                                        <label> Footer description :</label>
                                        <div className='my-3 w-[580px]'>
                                             
                                            <TextEditter txt={content} handleTxt={handleContent} />
                                        </div>
                                    </div>
                                </div >
                                <div className='mt-8 text-end'>
                                    <button className='px-4 py-2 bg-blue-400 hover:bg-blue-700 border rounded-md' type='submit' >  Submit </button>
                                </div>
                            </form >

                            <ToastContainer />
                        </div >
                    </div >
            }
        </>
    )
}

export default CreateOrg
