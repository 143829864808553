import React, { useState, useEffect } from "react";
import TextEditter from "../../components/TextEditter";
import BaseBackButton from "../../components/BaseBackButton";
import {
  postReqParamheader,
  getReqParamheader,
  patchReqParam,
  deleteReqParamheader,
} from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import { useLocation, useNavigate } from "react-router-dom";
import BaseTabs from "../../components/BaseTabs";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../../components/PageSnipper";

function CreateCustomContact() {
  const [data, setData] = useState({});
  const [title, setTitle] = useState("Untitled");
  const [des, setDes] = useState("dummy");
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const [gId, setGId] = useState("");
  const path = useLocation().pathname;
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("Edit");
  const [contact, setContact] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    let g = path.split("/")[5];
    setOrg(orgid);
    setUser(userid);
    if (g !== "create") {
      setGId(g);
      setIsEdit(true);
    }
    if (g === "create") {
      setIsEdit(false);
    }
  }, []);
  useEffect(() => {
    if (orgId && userId) {
      getAllContact();
    }
    if (gId && isEdit) {
      fetchCustomContactDetails(gId);
    }
  }, [gId]);
  useEffect(() => {
    if (searchValue) {
      const newPacientes = contact.filter(
        (value) =>
          value.FirstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          value.Email.toLowerCase().includes(searchValue.toLowerCase())
      );
      setContact(newPacientes);
    } else {
      if (orgId && userId) {
        getAllContact();
      }
    }
  }, [searchValue]);

  const getAllContact = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/contact_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get contact details response", res?.data);
          setContact(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const fetchCustomContactDetails = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/customlist_detailsbyId/${id}/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get custom contact by id response", res?.data);
          let data = res?.data?.data;
          setLoading(false);
          setData(data);
          setTitle(res?.data?.data?.Team_Group_Name);
          setDes(res?.data?.data?.Team_Group_Description);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  const handleContent = (txt) => {
    setDes(txt);
  };
  const handleSave = () => {
    data.Team_Group_Name = title;
    data.Team_Group_Description = des;

    if (isEdit) {
      updateContact(data);
    } else {
      addContact(data);
    }
  };

  const addContact = (data) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/create_customlist/${orgId}/${userId}`;
    postReqParamheader(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("add custom contact response", res?.data);

          setGId(res?.data?.data?._id);
          setIsEdit(true);
          setLoading(false);
          setTimeout(() => {
            toast.success(res?.data?.message);
          }, 1000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  const updateContact = (data) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/updatecustomlist/${gId}/${orgId}/${userId}`;
    patchReqParam(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("update custom contact response", res?.data);
          setLoading(false);
          setTimeout(() => {
            toast.success(res?.data?.message);
          }, 1000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);

        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };

  const handleTab = (txt) => {
    setActiveTab(txt);
  };
  const handleSwich = (e) => {
    let id = e.target.value;
    let arr = data?.Members?.filter((item) => item?.memberId === id);
    if (arr?.length > 0) {
      handleRemoveContact(id);
    } else {
      handleAddContact(id);
    }
  };
  const handleCheck = (id) => {
    let arr = data?.Members?.filter((item) => item?.memberId === id);
    if (arr?.length > 0) {
      return true;
    }
    return false;
  };
  const handleAddContact = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/add_to_customlist/${id}/${orgId}/${userId}/${gId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("add contact in custom response", res?.data);
          fetchCustomContactDetails(gId);
          setLoading(false);
          setTimeout(() => {
            toast.success(res?.data?.message);
          }, 1000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  const handleRemoveContact = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/remove_from_customlist/${id}/${orgId}/${userId}/${gId}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("removecontact in custom response", res?.data);
          fetchCustomContactDetails(gId);

          setLoading(false);
          setTimeout(() => {
            toast.success(res?.data?.message);
          }, 1000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  return (
    <div className="relative px-4 w-full flex justify-center items-center flex-col">
      <div className="absolute top-0 left-10">
        <BaseBackButton text="Custom Contact List" />
      </div>
      <p className="text-2xl text-center pb-4">
        {" "}
        {title ? title : " Custom List"}{" "}
      </p>
      <div className="w-[50%]">
        <BaseTabs
          active={activeTab}
          handleTab={handleTab}
          tabList={["Edit", "Add/Remove Contact"]}
        />
      </div>

      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="w-[50%] py-2">
          {activeTab === "Edit" ? (
            <div className="flex justify-center items-center flex-col my-2">
              <div className="w-full">
                <label> Custom List Name :</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full px-4 py-2 my-3 rounded-md border"
                />
              </div>
              <div className="mt-2 w-full">
                <label> Custom List Description:</label>
                <div className="my-3">
                  <TextEditter txt={des} handleTxt={handleContent} />
                </div>
              </div>
              <div className="flex justify-end my-4 w-full">
                <button
                  className="border rounded-md mr-1.5 bg-blue-500 px-4 py-2 hover:bg-blue-800 text-white"
                  onClick={handleSave}
                >
                  {isEdit ? "Update Custom Contact " : "Save Custom Contact"}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center flex-col my-2">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Search contact by name or email"
                  className="w-full border px-4 py-2 bg-white rounded-md"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="mt-4 py-3 w-full">
                {contact?.map((item, i) => (
                  <div key={i}>
                    <div className="bg-white border rounded-md mb-2 px-4 py-2 w-full flex justify-between items-center">
                      <div className="flex justify-start gap-4">
                        <div className="border border-gray-400  w-12 h-12 bg-gray-100 rounded-full flex justify-center items-center">
                          <div>
                            {" "}
                            {item?.FirstName?.split("")[0].toUpperCase() || "M"}
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-1">
                            <p className="text-md text-gray-600">
                              {" "}
                              {item?.FirstName}
                            </p>
                            <p className="text-md text-gray-600">
                              {" "}
                              {item?.LastName}
                            </p>
                          </div>
                          <p className="text-sm text-gray-600">
                            {" "}
                            {item?.Email}
                          </p>
                        </div>
                      </div>
                      <div>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={item?._id}
                            checked={handleCheck(item?._id)}
                            className="sr-only peer"
                            onChange={handleSwich}
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="w-full flex justify-end">
                  <button
                    className="border bg-blue-400 rounded-md mr-1.5 px-6 py-2 mt-4"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default CreateCustomContact;
