import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { getItem } from '../../utils/storage'
import { getReqParamheader, patchReqParam } from '../../services/apiCall'
import { ToastContainer, toast } from 'react-toastify';
import { API_URL_CONSTANT } from '../../constants/apiConstant'

function OrgApproval() {
    const [verorgId, setVerOrgId] = useState('')
    const [verorgData, setVerOrgData] = useState('')
    const path = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
      let t = path?.search.split('=')[1]
      if(t){
        setVerOrgId(t);
        //get Org data from here
        getOrgbyId(t);
      }else{
        setVerOrgId("")
      }
    }, [])

    // useEffect(() => {
    //     getMemberDatabyId()
    // }, [orgId])

    const handleBack = () => {
        let currentorg = getItem('currentOrg');
        navigate(`/manage/${currentorg}`)
    }

    const handleRequest = () => {
        acceptInvitation()
    }

    const handleReject = () => {
        alert('Organization is not approved.')
        handleBack()
    }

    const getOrgbyId = (id) => {
        let url = `${API_URL_CONSTANT.baseUrl}/Organization_details/${id}`
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                   // console.log('get member details response', res?.data)
                    setVerOrgData(res?.data?.data)
                    console.log(res.data);
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
                toast.error(err?.response?.data?.message)
            })
    }

    const acceptInvitation = () => {
        let url = `${API_URL_CONSTANT.baseUrl}/verifyorg/${verorgId}`;
        getReqParamheader(url)
            .then((res) => {
                if (res?.status) {
                   // console.log('get acceptance response', res?.data)
                    // setMember(res?.data?.data)
                    alert("Organization is approved.")
                    handleBack()
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
                toast.error(err?.response?.data?.message)
            })
    }

    return (
         <div className='px-8 '>
            {
              <>
              <div className='flex justify-center'>
                  <div className='w-[50%]'>
                      <p className='text-2xl text-center py-2'><b>Organization Approval</b></p>
                      { verorgData ?
                        <div>
                          <p className='text-1xl mt-3'><b>{verorgData.OrganizationName}</b> has requested for approval.<br/><br/>Following are the details of the organization -</p>
                          <div>
                            <div className="py-2">Name: <b>{verorgData.OrganizationName}</b></div>
                            <div className="py-2">Description: <b>{verorgData.Organization_Description}</b></div>
                            <div className="py-2">Address: <b>{verorgData.address.Street}, {verorgData.address.City}, {verorgData.address.State}, {verorgData.address.Pincode}</b></div>
                            <div className="py-2">Logo: <img src={verorgData.logo}/></div>
                            <div className="py-2">Email: <b>{verorgData.primary_email}</b></div>
                          </div>
                          <p className='text-1xl mt-3 '>Do you want to approve this organization?</p>
                          <div className='flex justify-between gap-4 my-3'>
                              <button className='border rounded-md bg-white px-4 py-1 w-full hover:border-blue-400'
                                  onClick={handleReject}
                              > Reject</button>
                              <button className='border rounded-md bg-blue-400 px-4 py-1 w-full hover:bg-blue-600'
                                  onClick={handleRequest}
                              > Accept</button>
                          </div>
                        </div>
                      : <div>Wrong information of Organization</div>
                      }
                  </div>
              </div>
            </>
          }
          </div>
          // <ToastContainer />
    )
}

export default OrgApproval
