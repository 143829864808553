import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { postReqParam, getReq } from "../services/apiCall";
import { useNavigate } from "react-router-dom";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { CheckMark } from "../assests/icons/email/BaseIcon";
import PageSnipper from "../components/PageSnipper";

function Signup() {
  const [fname, setFirstname] = useState("");
  const [lname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [vEmail, setVEmail] = useState({ msg: "", v: false });
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    //
    let data = {
      firstName: fname,
      lastName: lname,
      email: email,
      password: password,
      // role: 'admin'
    };
    signup(data);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const emailValidation = (val) => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(val)) {
      setVEmail({
        ...vEmail,
        v: true,
        msg: "Please provide a valid email address",
      });
      return false;
    } else {
      setVEmail({ ...vEmail, v: false, msg: "" });
      return true;
    }
  };
  const verifyEmail = (id) => {
    // verify/:userId",
    let url = `${API_URL_CONSTANT.baseUrl}/verify/${id}`;
    getReq(url)
      .then((res) => {
        if (res?.status) {
          console.log("verify email response", res?.data);
          //toast.success(' Success !')
        } else {
          console.log("response err", res?.message);
          // toast.success(res?.message)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong !");
      });
  };
  const signup = async (data) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/register`;
    await postReqParam(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("response", res?.data);
          setLoading(false);
          toast.success("Registation Success !");
          // setTimeout(()=>{
          //     navigate(-1);
          // },5000)
          setSubmit(true);
          let id = res?.data?.data?._id;
          // verifyEmail(id)
        }
      })
      .catch((err) => {
        console.log("err", err?.response?.data);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const signin = (data) => {
    setLoading(false);
    navigate("/login");
  };

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="w-full mt-5 flex justify-center items-center flex-col">
          {submit ? (
            <div className="text-gray-700 h-[50vh] flex justify-center items-center flex-col text-xl text-center">
              <p>We have sent a verification email to : </p>
              <p className="text-blue-500 font-bold">{email} </p>
              <p>Status: sent</p>
              <p>
                If you don't see verification email <br />
                please check SPAM folder
              </p>
            </div>
          ) : (
            <>
              <div className="w-[80%] md:w-[50%] mb-4 md:mb-6 text-gray-500 ">
                <p> Thanks for your interest in Smorgborg!</p>
                <p className="mt-2">
                  {" "}
                  Sign up using the form below and we will send you an email to
                  confirm your account.
                </p>
              </div>
              <form
                className="bg-white shadow-md rounded w-[80%] md:w-[45%]"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-wrap -mx-3">
                  <div className=" w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block text-gray-700 text-sm mb-2">
                      First Name
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                      type="text"
                      placeholder="first name"
                      required
                      value={fname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 w-full md:w-1/2 px-3">
                    <label className="block text-gray-700 text-sm mb-2">
                      Last Name
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700
                         leading-tight focus:shadow-outline"
                      type="text"
                      placeholder="last name"
                      required
                      value={lname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm  mb-2">
                    Personal Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700
                         leading-tight focus:shadow-outline"
                    type="email"
                    placeholder="email"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      emailValidation(e.target.value);
                    }}
                  />

                  <p
                    className={
                      vEmail?.v ? "block text-red-700 font-light" : "hidden"
                    }
                  >
                    {vEmail?.msg}
                  </p>
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm  mb-2">
                    Password
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3
                         text-gray-700 mb-3 leading-tight focus:shadow-outline"
                    type={passwordType}
                    placeholder="*********"
                    required
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />

                  <div className="flex mb-3">
                    <input
                      type="checkbox"
                      value="password"
                      checked={passwordType !== "password"}
                      onChange={(e) => togglePassword()}
                    />

                    <label className="block text-gray-700 text-sm ml-3">
                      {passwordType === "password"
                        ? " Show Password"
                        : "Hide Password"}
                    </label>
                  </div>

                  <div className="block text-gray-700 text-sm  mb-2">
                    <p className="pb-3">
                      All checkmarks must turn green, password must have:
                    </p>
                    <p className="flex gap-3">
                      <CheckMark type={password?.length > 7} />
                      <span> At least 8 characters </span>
                    </p>
                    <p className="flex gap-3">
                      <CheckMark
                        type={
                          password && password[0] === password[0]?.toUpperCase()
                        }
                      />
                      <span> Fitst letter should be uppercase </span>
                    </p>
                    <p className="flex gap-3">
                      <CheckMark type={password?.match(/[a-z]/)} />
                      <span>At least 1 lowercase letter</span>
                    </p>
                    <p className="flex gap-3">
                      <CheckMark
                        type={
                          password?.match(/[0-9]/) &&
                          password?.match(/[!@#$%^&*]/)
                        }
                      />
                      <span>At least 1 number and special character</span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full mb-4">
                  <p>
                    If you are a registered user,{" "}
                    <span
                      className="text-blue-800 cursor-pointer"
                      onClick={signin}
                    >
                      &nbsp;<em>SIGN IN</em>
                    </span>
                  </p>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 
                    rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Register
                  </button>
                </div>
              </form>
            </>
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default Signup;
