// import React from "react";
// import { Link } from "react-router-dom";

// export default function HomeIntroBanner() {
//   return (
//     <div className="flex flex-col md:flex-row min-h-[480px] bg-white">
//       <div className="w-full md:w-1/2 p-8 flex items-center justify-center">
//         <div className="text-center md:text-left">
//           <p className="text-6xl leading-[1.2] md:text-5xl md:leading-[1.2] mb-12">
//             Smorgborg helps clubs, organizations, and communities get organized
//             and connected
//           </p>
//           <Link to="/signup">
//             <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//               Sign Up Today
//             </button>
//           </Link>
//         </div>
//       </div>
//       <div className="w-full md:w-1/2 flex items-center">
//         <img
//           alt="Collaboration"
//           src="https://smorgborg.imgix.net/assets/peopleNetwork.png?fit=max&w=1200"
//           className="w-10/12  object-cover opacity-90"
//         />
//       </div>
//     </div>
//   );
// }

///Responsive for different screen size
// Small devices (up to 576px): This includes small smartphones like the iPhone SE and similar models.

// Medium devices (576px to 768px): This includes larger smartphones like the iPhone X and similar models, as well as smaller tablets.

// Large devices (768px to 992px): This includes larger tablets and small laptops.

// Extra large devices (992px to 1200px): This includes larger laptops and desktops.

// XXL devices (1200px and up): This includes large desktops and wide screens.

// text-4xl: reduces the font size of the heading on smaller screens
// mb-4 md:mb-12: reduces the margin bottom on smaller screens
// w-full md:w-10/12: adjusts the width of the image on smaller screens
// object-cover: ensures the image maintains its aspect ratio
// opacity-90: reduces the opacity of the image for better contrast with the text.

import React from "react";
import { Link } from "react-router-dom";

export default function HomeIntroBanner() {
  return (
    <div className="flex flex-col md:flex-row gap-4 min-h-[480px] bg-white px-6 md:px-12 xl:px-16 items-center py-4 md:py-10">
      <div className="w-full md:w-1/2 flex items-center justify-center order-2 md:order-1">
        <div className="md:text-left">
          <p className="text-2xl md:text-4xl lg:text-5xl 2xl:text-6xl leading-[1.2] text-balance md:leading-relaxed 2xl:leading-[4rem] mb-4 md:mb-8">
            Smorgborg helps clubs, organizations, and communities get organized
            and connected
          </p>
          <Link to="/signup">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 md:px-8 rounded md:py-4 2xl:text-2xl ">
              Sign Up Today
            </button>
          </Link>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex items-center order-1 md:order-2">
        <img
          alt="Collaboration"
          src="https://smorgborg.imgix.net/assets/peopleNetwork.png?fit=max&w=1200"
          className="w-full md:w-10/12  object-cover opacity-90"
          loading="lazy"
        />
      </div>
    </div>
  );
}
