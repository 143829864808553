import React, { useState, useEffect, useRef } from "react";
import TextEditter from "../../components/TextEditter";
import {
  postReqParamheaderFile,
  getReqParamheader,
  postReqParamheader,
  putReqParamheaderFile,
} from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import Modal from "../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import BaseTabs from "../../components/BaseTabs";
import ArticleEditor from "../../components/ArticleEditor";
import PageSnipper from "../../components/PageSnipper";
import BaseBackButton from "../../components/BaseBackButton";

function CreateWidget() {
  const [widgettitle, setWidgetTitle] = useState("");
  const [domain, setDomain] = useState("");
  const [org, setOrg] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPublished, setIsPublice] = useState(false);
  const [widgetId, setWidgetId] = useState("");
  const path = useLocation().pathname;
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("Edit");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [activeAlbum, setActiveAlbum] = useState({
    name: "unsorted",
    data: [],
  });
  const [msg, setMsg] = useState(
    "Your work is saved, but has not been published."
  );

  const count = useRef(0);

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    let article = path.split("/")[4];
    setOrg(orgid);
    setUser(userid);
    if (article !== "create") {
      setWidgetId(article);
      setIsEdit(true);
    }
    if (article === "create") {
      // setArticleId(article)
      setIsEdit(false);
    }
  }, []);
  useEffect(() => {
    if (widgetId && isEdit) {
      fetchArticledata(widgetId);
    }
  }, [widgetId, user]);

  const fetchArticledata = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/widget_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("widget data: ", res?.data);
          let result = res?.data?.data;
          console.log(result);
          setWidgetTitle(result?.Widget_Name);
          setDomain(result?.Website_Domain);
          /*if (result?.publishedAt) {
                        setIsPublice(true)
                        setMsg('Your work is saved and published.')
                    }
                    else {
                        setMsg('Your work is saved, but has not been published.')
                    }*/
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleTab = (txt) => {
    setActiveTab(txt);
  };

  const handlePublice = () => {
    if (window.confirm("Do you want to publish this widget?")) {
      handlePubliceArticle(widgetId);
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    }
  };
  const handlePubliceArticle = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/publish_Widgets/${id}/${user}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("publice widget response", res?.data);
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleSave = () => {
    setMsg("Your work is being saved .....");
    let data = {};
    data.Widget_Name = widgettitle;
    data.Website_Domain = domain;
    if (data && user && org) {
      if (isEdit) {
        updateArticle(data, user, org);
      } else {
        saveArticle(data, user, org);
      }
    }
  };

  const saveArticle = (data, userId, orgId) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Create_widget/${orgId}/${userId}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("save widget response", res?.data);
          // toast.success(res?.data?.message)
          //handlePublice(res?.data?.data?._id)
          setWidgetId(res?.data?.data?._id);
          setIsEdit(true);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const updateArticle = (data, userId, orgId) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Update_widgets/${widgetId}/${orgId}/${userId}`;
    putReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("update article response", res?.data);
          //toast.success(res?.data?.message)
          //handlePublice(articleId)
          if (widgetId) {
            fetchArticledata(widgetId);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const createWidgetHere = () => {
    setActiveTab("Publish Settings");
    handleSave();
  };

  const updateDomain = (e) => {
    let dom = e.target.value;
    if (dom.includes("www") || dom.includes("http")) {
      alert("Don't include any www or http in domain name");
      //return;
    }
    setDomain(dom);
  };

  return (
    <div className="relative px-4 w-full flex justify-center items-center flex-col">
      <div className="absolute top-0 left-10">
        <BaseBackButton text="Widget List" />
      </div>

      <p className="text-2xl text-center">
        {" "}
        {!widgettitle ? "Untitled Widget" : widgettitle}{" "}
      </p>

      <div className="w-[40%] mt-4">
        <BaseTabs
          active={activeTab}
          handleTab={handleTab}
          tabList={["Edit", "Publish Settings"]}
        />
      </div>
      {activeTab === "Edit" ? (
        <div className="flex justify-center items-center flex-col my-3">
          <div className="w-[50%]">
            <label>Widget Name:</label>
            <input
              type="text"
              value={widgettitle}
              // onBlur={handleAutoSave}
              onInput={(e) => {
                setWidgetTitle(e.target.value);
              }}
              placeholder="Enter name of the widget"
              className="w-full px-4 py-2 my-3"
            />

            <label>Domain of your website:</label>
            <input
              type="text"
              value={domain}
              // onBlur={handleAutoSave}
              onInput={(e) => {
                updateDomain(e);
              }}
              placeholder="Domain should be without http/https and www"
              className="w-full px-4 py-2 my-3"
            />

            <label>Fields:</label>

            <div className="w-full">
              <input
                type="radio"
                // onBlur={handleAutoSave}
                id="firstname"
                className="px-4 py-2 my-3 mx-3"
                onChange={() => console.log()}
                checked
              />
              <label htmlFor="firstname">First Name (Required)</label>
            </div>

            <div className="w-full my-3">
              <input
                type="radio"
                // onBlur={handleAutoSave}
                id="lastname"
                className="px-4 py-2 my-3 mx-3"
                onChange={() => console.log()}
                checked
              />
              <label htmlFor="lastname">Last Name (Required)</label>
            </div>

            <div className="w-full my-3">
              <input
                type="radio"
                // onBlur={handleAutoSave}
                id="emailid"
                className="px-4 py-2 my-3 mx-3"
                onChange={() => console.log()}
                checked
              />
              <label htmlFor="emailid">Email (Required)</label>
            </div>
          </div>

          <div className="flex justify-end  w-full  my-2">
            <button
              className="border rounded-md bg-blue-600 text-white px-4 py-2 hover:bg-blue-400"
              onClick={() => createWidgetHere()}
            >
              Next
            </button>
          </div>
          <p className="flex justify-end  w-full text-gray-500 text-sm">
            {" "}
            {msg}
          </p>
        </div>
      ) : (
        <div className="w-[50%] text-start">
          <p className="text-sm text-gray-500">
            This widget is currently
            <span className="text-sm text-blue-500 pl-2">
              {isPublished ? "published" : "not published"}{" "}
            </span>
          </p>
          <div className="flex justify-between pt-6">
            <button
              className="border rounded-md  bg-white text-gray-500 px-4 py-2 hover:border-blue-400 hover:text-blue-400"
              onClick={() => setActiveTab("Edit")}
            >
              {" "}
              Back
            </button>
            <button
              className="border rounded-md  bg-blue-600 text-white px-4 py-2 hover:bg-blue-400"
              onClick={() => handlePublice()}
            >
              {" "}
              Publish and Exit
            </button>
          </div>
          <p className="text-end mt-3 text-gray-500 text-sm"> {msg}</p>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default CreateWidget;
