import React from "react";
import HomeForm from "./HomeForm";

export default function HomeContactUs() {
  return (
    <div
      id="tools"
      className="h-auto bg-white flex flex-col md:flex-row items-start gap-6 px-6 md:px-12 xl:px-16 py-4 md:py-10"
    >
      <div className="w-full md:w-3/5 text-lg md:text-2xl 2xl:text-3xl">
        <p className="leading-10 font-bold pb-4">
          It's Hard to Fit Everything We Do on One Page
        </p>
        <p className="leading-10 text-justify w-[80%]">
          But if you're still interested in learning more about how Smorgborg
          can help your organization, just fill out our contact form, and we’ll
          set up a personal product demo for you with our founder, John.
        </p>
      </div>
      <div className="w-full md:w-2/5">
        <HomeForm />
      </div>
    </div>
  );
}
