import React from "react";
import { useNavigate } from "react-router-dom";

function ProfileSetting() {
  const navigate = useNavigate();

  const handleChangePassword = () => {
    navigate("/user/setting/change-password");
  };
  const handleEditProfile = () => {
    navigate("/user/setting/edit-profile");
  };
  return (
    <div className="w-full bg-gray-100 h-[70vh]">
      <div className="py-8 px-4">
        <div className="flex justify-center items-center">
          <div className="bg-white border rounded-sm py-4 w-[40%]">
            <p className="px-6"> General User Settings</p>
            <p className="border-b pb-4"> </p>
            <div className="px-6 py-4 text-blue-400 ">
              <p
                className="mb-2 hover:text-blue-700 cursor-pointer"
                onClick={handleEditProfile}
              >
                {" "}
                My Profile
              </p>
              <p
                className="mb-2 hover:text-blue-700 cursor-pointer"
                onClick={handleChangePassword}
              >
                {" "}
                Change Password
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSetting;
