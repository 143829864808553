import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function BaseBackButton({
  text = "",
  type = "anchor",
  clickHandler,
}) {
  const navigate = useNavigate();
  if (type === "anchor") {
    return (
      <a
        className="text-small text-blue-500 cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        {text ? <span className="ml-2">{text}</span> : null}
      </a>
    );
  }
  if (type === "button") {
    return (
      <Button type="link" className="pl-0" onClick={clickHandler}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="ml-2">{text}</span>
      </Button>
    );
  }
}
