import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { setItem, getItem, deleteItem } from "../../utils/storage";
import { getReqParamheader, patchReqParam } from "../../services/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import BaseBackButton from "../../components/BaseBackButton";

function EditMember() {
  const [member, setMember] = useState({});
  const [invite, setInvite] = useState(false);
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const [memberId, setMemberId] = useState("");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState("");

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    let memb = path.split("/")[4];
    let checkMember = getItem("member");
    setOrg(orgid);
    if (orgid) {
      getCurrentOrg(orgid);
    }
    setUser(userid);
    setMemberId(memb);
    if (checkMember === "edit") {
      setInvite(!invite);
    }
  }, []);

  useEffect(() => {
    if (orgId && userId && memberId) {
      getMemberDatabyId();
    }
  }, [orgId]);

  const handleBack = () => {
    let url = path.split("/").splice(1, 4).join("/");
    console.log(url);
    // navigate(url)
    navigate(`/manage/${orgId}/team`);
  };

  const handleRequest = () => {
    acceptInvitation();
  };
  const handleSubmit = () => {
    deleteItem("member");
    let data = {};
    data.Title = member?.Title || "";
    data.Role = member?.Role;
    updateMember(data);
  };
  const handleReject = () => {
    alert("You are rejected ....!");
    //navigate(-1)
    deleteItem("member");
    handleBack();
  };
  const updateMember = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/update_member_details/${memberId}/${orgId}/${userId}`;
    patchReqParam(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log('update member details response', res?.data)
          // setMember(res?.data?.data)
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const getMemberDatabyId = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/member_details/${memberId}/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get member details response', res?.data)
          setMember(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const getCurrentOrg = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Organization_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setOrgName(res?.data?.data?.OrganizationName);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const acceptInvitation = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/Join_user/${memberId}/${orgId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get acceptance response', res?.data)
          // setMember(res?.data?.data)
          toast.success(res?.data?.message);
          deleteItem("member");
          setTimeout(() => {
            handleBack();
          }, 2000);

          // alert('accepted....')
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        deleteItem("member");
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div className="px-8 ">
      {!invite ? (
        <>
          <div className="flex justify-center">
            <div className="w-[50%]">
              <p className="text-2xl">
                Organization Team {member?.Role} Invitation{" "}
              </p>
              <p className="text-1xl mt-3">
                {" "}
                You have been invited to join <b>{orgName}</b>
              </p>
              <p className="text-1xl mt-3 ">
                <em>Role:</em> {member?.Role}{" "}
              </p>
              <div className="flex justify-between gap-4 my-3">
                <button
                  className="border rounded-md bg-white px-4 py-1 w-full hover:border-blue-400"
                  onClick={handleReject}
                >
                  {" "}
                  Reject
                </button>
                <button
                  className="border rounded-md bg-blue-400 px-4 py-1 w-full hover:bg-blue-600"
                  onClick={handleRequest}
                >
                  {" "}
                  Accept
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <BaseBackButton text="Team Member List" />
          <p className="text-2xl text-center"> {member?.FirstName} </p>
          <div className="flex justify-center">
            <div className="mt-6 w-[40%] ">
              <label className="text-gray-600">
                {" "}
                Team Member's First Name:
              </label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                disabled
                value={member?.FirstName}
              />
              <label className="text-gray-600"> Team Member's Last Name:</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                disabled
                value={member?.LastName}
              />
              <label className="text-gray-600"> Team Member's Email:</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                disabled
                value={member?.Email}
              />
              <label>Team Member's Role:</label>
              <div className="pb-4 mt-2 text-sm text-gray-600">
                <div className="flex items-start gap-3">
                  <div>
                    <input
                      type="radio"
                      value="Admin"
                      name="role"
                      checked={member?.Role === "Admin"}
                      onChange={(e) =>
                        setMember({ ...member, Role: e.target.value })
                      }
                    />
                  </div>
                  <div className="">
                    Administrator: This role can perform most of the actions
                    that an owner can, but cannot remove an owner or delete the
                    organization.
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div>
                    <input
                      type="radio"
                      value="Team"
                      name="role"
                      checked={member?.Role === "Team"}
                      onChange={(e) =>
                        setMember({ ...member, Role: e.target.value })
                      }
                    />
                  </div>
                  <div className="">
                    {" "}
                    Team: This role can be included in communications with the
                    organization, including chat and email, but does not have
                    access to the organization's dashboard or administrative
                    functions.
                  </div>
                </div>
              </div>
              <label className="text-gray-600">
                {" "}
                Team Member's Title (optional):
              </label>
              <input
                type="text"
                value={member?.Title}
                className="px-4 py-1 my-2 w-full border"
                onChange={(e) =>
                  setMember({ ...member, Title: e.target.value })
                }
              />
              <button
                className="px-4 py-2 bg-blue-500 rounded-sm my-3 w-full text-white"
                onClick={handleSubmit}
              >
                {" "}
                Submit{" "}
              </button>
            </div>
          </div>
        </>
      )}

      <ToastContainer />
    </div>
  );
}

export default EditMember;
