import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LeftSidebar from "../components/LeftSidebar";
import { getItem } from "../utils/storage";
import {
  LayoutDashboard,
  Mail,
  Newspaper,
  SquareUserRound,
  Users,
  Images,
  CalendarClock,
  MonitorCog,
} from "lucide-react";

function ModuleLayout({ children, user }) {
  const [navItems, setNavItems] = useState({});
  const [org, setOrg] = useState("");
  const [loc, setLoc] = useState("");
  const path = useLocation().pathname;
  let orgname = getItem("currentOrg");
  let username = getItem("currentuser");

  useEffect(() => {
    toggleOverFlowHidden(true);
    return () => {
      toggleOverFlowHidden(false);
    };
  }, []);

  useEffect(() => {
    if (user && path.split("/")[1] == "user") {
      getUserData();
      setUserStorage();
    } else {
      getData();
      setStorage();
    }
  }, [orgname, username, path]);

  useEffect(() => {
    if (user) {
      getUserData();
    } else {
      getData();
    }
  }, [org, loc]);

  function toggleOverFlowHidden(isHidden) {
    if (isHidden) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }

  const setStorage = () => {
    let orgname = getItem("currentOrg");
    if (orgname) {
      setOrg(orgname);
      let base = `/manage/${path.split("/")[2]}`;
      setLoc(base);
    }
  };
  const setUserStorage = () => {
    let username = getItem("currentuser");
    if (username) {
      setOrg(username);
      let base = `/user/setting`;
      setLoc(base);
    }
  };

  const getData = () => {
    setNavItems({
      ...navItems,
      orgName: org,
      items: [
        {
          name: "Dashboard",
          url: "",
          icon: <LayoutDashboard size={20} />,
        },
        {
          name: "Email",
          url: "/email",
          icon: <Mail size={20} />,
        },
        {
          name: "Article",
          url: "/article",
          icon: <Newspaper size={20} />,
        },
        {
          name: "Contact",
          url: "/contact",
          icon: <SquareUserRound size={20} />,
        },
        {
          name: "Team",
          url: "/team",
          icon: <Users size={20} />,
        },
        {
          name: "Photo",
          url: "/photo",
          icon: <Images size={20} />,
        },
        {
          name: "Events",
          url: "/events",
          icon: <CalendarClock size={20} />,
        },
        {
          name: "Widgets",
          url: "/widgets",
          icon: <MonitorCog size={20} />,
        },
      ],
    });
  };
  const getUserData = () => {
    setNavItems({
      ...navItems,
      orgName: org,
      items: [
        {
          name: "Setting",
          url: "",
        },
        // {
        //     name: 'Event',
        //     url: ''
        // },
        // {
        //     name: 'Team',
        //     url: ''
        // },
      ],
    });
  };

  return (
    <div className="w-full flex module-layout-container absolute">
      <div className="w-[10%] md:w-[20%] h-full fixed bg-[#001529]">
        <LeftSidebar
          orgName={navItems?.orgName}
          items={navItems?.items}
          baseUrl={loc}
        />
      </div>
      <div className="w-[90%] md:w-[80%] relative left-20 md:left-[20%] h-full overflow-auto bg-gray-100 py-6 overflow-y-auto">
        {children}
      </div>
    </div>
  );
}

export default ModuleLayout;
