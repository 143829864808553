import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { API_URL_CONSTANT } from '../../constants/apiConstant'
import { getReqParamheaderUnAuth, postReqParamheader } from '../../services/apiCall'
import PageSnipper from '../../components/PageSnipper'

function ViewWidget() {
    const [articleId, setArticleId] = useState('')
    const [data, setData] = useState({})
    const path = useLocation().pathname
    const [loading, setLoading] = useState(false);
    const [domainValid, setDomainValid] = useState(false);
    const [currentwidget, setCurrentWidget] = useState("");
    const [datasubmitted, setDataSubmitted] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        let widgetid = path.split('/')[2];
        setCurrentWidget(widgetid);
        validateDomain(widgetid);
    }, [])

    //Validate here if the domain is right
    const validateDomain = (widgetId) => {
        //setLoading(true)
        let uri = window.location.ancestorOrigins[0];
        let domain1 = uri.replace(/(^\w+:|^)\/\//, '');
        let domain = domain1.replace(/^www\./,'')
        console.log(domain);
        // if(domain === "localhost:3000"){
        //     setDomainValid(true);
        // }else{
        //     setDomainValid(false);
        // }
        let url = `${API_URL_CONSTANT.baseUrl}/widget_details/${widgetId}`

        getReqParamheaderUnAuth(url)
            .then((res) => {
                if (res?.status) {
                    let dataa = res?.data?.data
                    console.log(dataa)
                    if(dataa?.Website_Domain !== domain){
                        setLoading(false)
                        setDomainValid(false)
                    }else{
                        setDomainValid(true)
                        setLoading(false)
                        setData(dataa)
                    }
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
                setLoading(false)
                alert("Unknown error happened. Please try later.")
            })
    }

    const saveNewsLetterData = () => {
       let url = `${API_URL_CONSTANT.baseUrl}/addcontactwidget/${currentwidget}`
        postReqParamheader(url, data)
            .then((res) => {
                if (res?.status) {
                    // alert("success")
                    console.log('publice widget response', res?.data)
                    // toast.success(res?.data?.message)
                    // setTimeout(() => {
                    //     navigate(-1)
                    // }, 2000)
                    setDataSubmitted(true);
                    setDomainValid(false);
                }
                else {
                    console.log('response err', res?.message)
                }
            })
            .catch(err => {
                console.log('err', err)
                // toast.error(err?.response?.data?.message)
            })
    }

    return (
        <div className='flex justify-center min-h-screen bg-gray-100'>
            {
                loading ?
                    <PageSnipper loading={loading} />
                    :
                    domainValid ?
                    <div className='w-full px-8 py-4 mt-4'>
                        <div className='w-full'>
                            <label>First Name:</label>
                            <input type='text'
                                // onBlur={handleAutoSave}
                                onChange={(e) => {
                                    setData({ ...data, FirstName: e.target.value })
                                }}
                                className='w-full px-4 py-2 my-3' />
                        </div>
                        <div className='w-full'>
                            <label>Last Name:</label>
                            <input type='text'
                                // onBlur={handleAutoSave}
                                onChange={(e) => {
                                    setData({ ...data, LastName: e.target.value })
                                }}
                                className='w-full px-4 py-2 my-3' />
                        </div>
                        <div className='w-full'>
                            <label>Email:</label>
                            <input type='text'
                                // onBlur={handleAutoSave}
                                onChange={(e) => {
                                    setData({ ...data, Email: e.target.value })
                                }}
                                className='w-full px-4 py-2 my-3' />
                        </div>
                        <button className='border rounded-md  bg-blue-600 text-white px-4 py-2 hover:bg-blue-400'
                                onClick={() => saveNewsLetterData()}
                            >Submit</button>
                    </div>
                    : datasubmitted ?
                    <div className='w-full px-8 py-4 mt-4'>
                        Thank you for submitting your information.
                    </div>
                    :
                    <div className='w-full px-8 py-4 mt-4'>
                        You are accessing the widget from an invalid domain. Please reach out to admin for support.
                    </div>
            }
        </div>
    )
}

export default ViewWidget
