import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  postReqParam,
  postReqParamheaderFile,
  patchReqParam,
} from "../services/apiCall";
import { useLocation, useNavigate } from "react-router-dom";
import { getItem, setItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { CheckMark } from "../assests/icons/email/BaseIcon";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [npassword, setnPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [npasswordType, nsetPasswordType] = useState("password");
  const navigate = useNavigate();

  useEffect(() => {
    let user = getItem("user_id");
    if (user) {
      setUserId(user);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //
    let data = {};
    data.currentPassword = npassword;
    data.newPassword = password;
    if (password && userId) {
      changePassword(data, userId);
    }
  };

  const togglePassword = (name) => {
    if (name === "new") {
      if (npasswordType === "password") {
        nsetPasswordType("text");
        return;
      }
      nsetPasswordType("password");
    } else {
      if (passwordType === "password") {
        setPasswordType("text");
        return;
      }
      setPasswordType("password");
    }
  };
  const changePassword = (data, id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/changepassword/${id}`;
    patchReqParam(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("response", res?.data);
          // setItem('token', res?.data?.data?.token)
          toast.success("Change Password Successfully ..!");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        // toast.error('Something went wrong !')
      });
  };
  return (
    <div className="px-6 flex justify-center items-center">
      <div className="w-[80%] md:w-[50%] mx-auto">
        <p className="text-bold text-2xl text-left mb-3">Change Password</p>
        <form
          className="bg-white shadow-md rounded px-8 py-10"
          onSubmit={handleSubmit}
        >
          <>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm  mb-2">
                Current Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3
                         text-gray-700 leading-tight focus:shadow-outline"
                type={passwordType}
                placeholder="*********"
                required
                value={npassword}
                onChange={(e) => setnPassword(e.target.value)}
              />
              <div className="flex mt-2">
                <input
                  type="checkbox"
                  name="old"
                  value="password"
                  checked={passwordType !== "password"}
                  onChange={(e) => togglePassword(e.target.name)}
                />

                <label className="block text-gray-700 text-sm ml-3">
                  {passwordType === "password"
                    ? " Show Password"
                    : "Hide Password"}
                </label>
              </div>
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm  mb-2">
                New Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3
                         text-gray-700 leading-tight focus:shadow-outline"
                type={npasswordType}
                placeholder="*********"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex mb-3">
              <input
                type="checkbox"
                name="new"
                value="password"
                checked={npasswordType !== "password"}
                onChange={(e) => togglePassword(e.target.name)}
              />

              <label className="block text-gray-700 text-sm ml-3">
                {npasswordType === "password"
                  ? " Show Password"
                  : "Hide Password"}
              </label>
            </div>
            <div className="block text-gray-700 text-sm  mb-2">
              <p className="pb-3">
                All checkmarks must turn green, new password must have:
              </p>
              <p className="flex gap-3">
                <CheckMark type={password?.length > 7} />
                <span> At least 8 characters </span>
              </p>
              <p className="flex gap-3">
                <CheckMark
                  type={password && password[0] === password[0]?.toUpperCase()}
                />
                <span> Fitst letter should be uppercase </span>
              </p>
              <p className="flex gap-3">
                <CheckMark type={password?.match(/[a-z]/)} />
                <span>At least 1 lowercase letter</span>
              </p>
              <p className="flex gap-3">
                <CheckMark
                  type={
                    password?.match(/[0-9]/) && password?.match(/[!@#$%^&*]/)
                  }
                />
                <span>At least 1 number and special character</span>
              </p>
            </div>
            <div className="flex items-center justify-end mt-8">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 
                         rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </>
          <ToastContainer />
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
