import React from "react";

export default function HomeOrgNotVerified({ orgName }) {
  return (
    <div className="flex justify-center items-center w-full py-20 flex-col">
      <div className="flex-row py-4 text-xl text-center">
        Your organization <b>{orgName}</b> has not been approved yet.
        <br />
        Please contact{" "}
        <em>
          <a href="mailto:info@sdvisualarts.net">info@sdvisualarts.net</a>
        </em>{" "}
        for more information.
      </div>
    </div>
  );
}
