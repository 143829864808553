import React from "react";

function DefaultFoolter({ orgData, user }) {
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  let des = htmlToReactParser.parse(orgData?.email_Footer);

  return (
    <div
      style={{
        backgroundColor: "#E2E2E2",
        textAlign: "center",
        margin: "0 auto",
        padding: "16px",
        maxWidth: "600px",
        borderRadius: "2px",
      }}
    >
      <h3>
        {new Date().getFullYear()} {orgData?.OrganizationName} - All Rights
        Reserved
      </h3>
      <div style={{ fontSize: "16px", fontWeight: "300" }}>{des}</div>
      <div style={{ fontSize: "16px", fontWeight: "300" }}>
        {orgData?.address?.Street} <br />
        {orgData?.address?.City} {orgData?.address?.State}{" "}
        {orgData?.address?.Pincode}
      </div>
    </div>
  );
}

export default DefaultFoolter;
