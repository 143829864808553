import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import {
  Edit,
  Photo,
  Artical,
  Button,
  WhiteSpace,
  Event,
  Divider,
  EventDigest,
} from "../../assests/icons/email/BaseIcon";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getItem } from "../../utils/storage";
import {
  getReqParamheader,
  postReqParamheaderFile,
} from "../../services/apiCall";
import Modal from "../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

var item = [
  {
    id: 1,
    name: "Text",
    txt: "You can edit this text by clicking on it!",
    icon: <Edit />,
    type: "text",
    style: {
      ts: "16px",
      tf: "Arial",
    },
  },

  {
    id: 2,
    name: "Logo",
    icon: <Photo />,
    type: "image",
    style: {
      logoUrl: "https://www.smorgborg.com/",
    },
  },
  {
    id: 3,
    name: "Heading",
    txt: "Sample Header",
    icon: <Edit />,
    type: "textH",
    style: {
      hs: "28px",
      hf: "Arial",
    },
  },
  {
    id: 4,
    name: "Photo",
    icon: <Photo />,
    type: "Photo",
    style: {
      logoUrl: "https://www.smorgborg.com/",
    },
  },

  {
    id: 5,
    name: "Button",
    icon: <Button />,
    type: "btn",
    style: {
      btnText: "Sample Button",
      btnAlign: "left",
      btnLink: "",
      btnSize: "14px",
    },
  },
  {
    id: 6,
    name: "Space",
    icon: <WhiteSpace />,
    type: "space",
    style: {},
  },
  {
    id: 7,
    name: "",
    icon: <Divider />,
    type: "hline",
    style: {},
  },
  {
    id: 8,
    name: "Article",
    icon: <Artical />,
    type: "artical",
    data: [],
    style: {
      margin: "0px",
      textAlign: "justify",
    },
    read: false,
  },
  {
    id: 9,
    name: "Event",
    icon: <Event />,
    type: "event",
    data: [],
    style: {},
    read: false,
  },
  {
    id: 10,
    name: "EventDigest",
    icon: <EventDigest />,
    type: "eventdigest",
    data: [],
    style: {},
  },
  // {
  //     id: 9,
  //     name: 'Ext.Event',
  //     icon: <Event />,
  //     type:'event'
  // },
  // {
  //     id: 9,
  //     name: 'Event Series',
  //     icon: <Event />,
  //     type:'event'
  // },
  // {
  //     id: 8,
  //     name: 'Event Digest',
  //     icon: <Event />,
  //     type:'event'
  // },
];
function InsertContents({ action }) {
  const [inset, setInsert] = useState([]);
  const [article, setArticle] = useState([]);
  const [feature, setFeature] = useState({});
  const [isArticle, setIsArticle] = useState(false);
  const [isPhoto, setIsPhoto] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [pfeature, setpFeature] = useState({});
  const [efeature, seteFeature] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [albumList, setAlbumList] = useState([]);
  const [unsorted, setUnsorted] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [activeAlbum, setActiveAlbum] = useState({
    name: "unsorted",
    data: [],
  });
  const [org, setOrg] = useState("");
  const [user, setUser] = useState("");
  const [isEventDigest, setIsEventDigest] = useState(false);
  const [digestperiod, setDigestPeriod] = useState("");
  const [show, setShow] = useState(false);
  const [imgfile, uploadimg] = useState({});
  const [currentDays, setCurrentDays] = useState(14);
  const [eventLoading, setEventLoading] = useState(false);
  // const override: CSSProperties = {
  //   display: "inline-block",
  //   margin: "0 auto",
  //   borderColor: "blue",
  //   verticalAlign: "center"
  // };
  const override = {
    display: "inline-block",
    margin: "0 auto",
    borderColor: "blue",
    verticalAlign: "center",
  };

  const path = useLocation().pathname;

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
    setInsert(item);
  }, []);

  useEffect(() => {
    if (isArticle) {
      let id = path.split("/")[2];
      if (id) {
        getArticleData(id);
      }
    }
  }, [isArticle]);

  useEffect(() => {
    if (isPhoto) {
      albumDetails();
      fetchPhotos();
    }
  }, [isPhoto]);

  useEffect(() => {
    if (isEvent) {
      getEventData();
    }
  }, [isEvent]);

  // useEffect(() => {
  //     if (user && org) {
  //         fetchPhotos()
  //         albumDetails()
  //         getEventData()
  //     }
  // }, [user])

  const handleClick = (feature) => {
    if (feature) {
      feature.id = Math.round(new Date().valueOf() / 1000); //handling ID issues
      if (feature?.type === "artical") {
        setIsArticle(!isArticle);
        setFeature(feature);
        // handleInsertArticle(feature)
      } else if (feature?.type === "Photo") {
        setIsPhoto(!isPhoto);
        setpFeature(feature);
      } else if (feature?.type === "event") {
        setIsEvent(!isEvent);
        seteFeature(feature);
      } else if (feature?.type === "eventdigest") {
        setIsEventDigest(!isEventDigest);
        seteFeature(feature);
      } else {
        action(feature);
      }
    }
  };

  const getArticleData = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/All_article_details/${id}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log("get article list response", res?.data);
          let filter = res?.data?.data?.filter(
            (item) => item?.Document_status === "Published"
          );
          setArticle(filter);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleInsertArticle = (item, i) => {
    setIsArticle(!isArticle);
    let obj = feature;
    obj.data = item;
    //  console.log('insert result', obj)
    action(obj);
  };

  const handleInsertEvent = (item, i) => {
    setIsEvent(!isEvent);
    let obj = efeature;
    obj.data = item;
    action(obj);
  };
  const handleInsertEventDigest = (item) => {
    setEventLoading(true);
    setIsEvent(!isEventDigest);

    let days = 7; //default
    let emaildigest = {};
    switch (item) {
      case "oneweek":
        days = 7;
        break;
      case "twoweeks":
        days = 14;
        break;
      case "fourweeks":
        days = 28;
        break;
      case "eightweeks":
        days = 56;
        break;
    }
    //Invoke API here and add to email
    let url = `${API_URL_CONSTANT.baseUrl}/event_digest/${org}/${user}?eventDays=${days}days`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          let obj = efeature;
          obj.data = res?.data?.data; //passing event object here
          action(obj);
        } else {
          console.log("response err", res?.message);
        }
        setEventLoading(false);
      })
      .catch((err) => {
        setEventLoading(false);
        console.log("err", err);
      });
  };
  const handleInsertPhoto = (url) => {
    setIsPhoto(!isPhoto);
    let obj = pfeature;
    obj.data = url;
    action(obj);
  };
  const albumDetails = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/albumdetails/${org}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setAlbumList(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const fetchPhotos = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/unsortedPhotos/${org}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get all photos response", res?.data);
          setUnsorted(res?.data?.data);
          setActiveAlbum({
            ...activeAlbum,
            name: "unsorted",
            data: res?.data?.data,
          });
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getEventData = () => {
    setEventLoading(true);
    let url =
      `${API_URL_CONSTANT.baseUrl}/events/${org}/${user}?eventDays=` +
      currentDays +
      `days`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get event list response", res?.data);
          // let filter=res?.data?.data.filter((item)=>(
          //     item?.fields?.Approved===true
          // ))
          //setEventList(res?.data?.data)
          if (currentDays === 14) {
            setEventList(res?.data?.data);
          } else {
            let currentEveList = eventList;
            if (currentEveList) {
              const finalEventL = currentEveList.concat(res?.data?.data);
              setEventList(finalEventL);
            }
          }
          setEventLoading(false);
        } else {
          setEventLoading(false);
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        setEventLoading(false);
        console.log("err", err);
      });
  };
  const handleselect = (val) => {
    if (val === "unsorted") {
      setActiveAlbum({ ...activeAlbum, name: "unsorted", data: unsorted });
    } else {
      let data = albumList.filter((item) => item?.Album_Name === val);
      setActiveAlbum({
        ...activeAlbum,
        name: data[0]?.Album_Name,
        data: data[0]?.photoslist,
      });
    }
  };

  const handleUploadSave = () => {
    setShow(false);
    if (user && org && imgfile) {
      let data = {};
      data.Photo_Title = "Test";
      data.files = imgfile?.pictureAsFile;
      uploadPhoto(data);
    }
  };

  const imgFilehandler = (e) => {
    if (e.target.files.length !== 0) {
      uploadimg({
        picturePreview: URL.createObjectURL(e.target.files[0]),
        pictureAsFile: e.target.files[0],
      });
    }
  };

  const uploadPhoto = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/uploadPhotos/${org}/${user}`;
    toast.info("Photo is being uploaded");
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("upload photo response", res?.data);
          uploadimg({});
          // setTitle('')
          fetchPhotos();
          toast.success("Photo has been uploaded successfully.");
        } else {
          console.log("response err", res?.message);
          toast.error("Unable to upload photo at this time");
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Unable to upload photo at this time");
      });
  };

  const handleLoadMore = () => {
    let more = currentDays;
    switch (currentDays) {
      //   case 7:
      //     setCurrentDays(14);
      //     break;
      case 14:
        setCurrentDays(21);
        break;
      case 21:
        setCurrentDays(28);
        break;
      default:
        setCurrentDays(7);
    }
    if (currentDays < 29) {
      getEventData();
    }
  };

  return (
    <div className="px-2">
      {isArticle ? (
        <div className="">
          <button
            className="border-2 rounded-md bg-white px-3 py-1 hover:text-blue-500"
            onClick={() => setIsArticle(!isArticle)}
          >
            Back to content
          </button>
          <div className="mt-2 h-[60vh] overflow-y-auto">
            {article.length > 0 ? (
              article?.map((item, i) => (
                <div key={i} className="border rounded-sm bg-white p-4 mb-2">
                  <p className="text-sm font-medium line-clamp-2 text-black">
                    {" "}
                    {item?.draft?.Title}
                  </p>
                  <p className="text-sm mt-1 font-semibold">
                    Published On:{" "}
                    <Moment format="DD-MMM-YYYY">
                      {item?.lastAccessedAt?.time}
                    </Moment>
                  </p>
                  <div
                    className="rounded-sm bg-blue-500 text-white mt-2 py-1 px-2 text-center hover:bg-blue-600 cursor-pointer"
                    onClick={() => handleInsertArticle(item, i)}
                  >
                    Insert into email
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full px-2 py-2" style={{ textAlign: "center" }}>
                Fetching Articles... Please wait
              </div>
            )}
          </div>
        </div>
      ) : isEvent ? (
        <div className="">
          <button
            className="border-2 rounded-md bg-white px-3 py-1 hover:text-blue-500"
            onClick={() => setIsEvent(!isEvent)}
          >
            Back to content
          </button>
          <div className="mt-2">
            {eventList.length > 0 ? (
              eventList?.map((item, i) => (
                <div
                  key={i}
                  className="border rounded-sm bg-white px-6 py-4 mb-2"
                >
                  <p className="text-1xl mb-1 text-black">
                    {" "}
                    {item?.Event_Name}
                  </p>
                  <p className="text-small"> Status: Approved </p>
                  <p className="text-small">
                    {" "}
                    Event Date: {item?.Event_Start_Date}{" "}
                  </p>
                  <p className="text-small">
                    {" "}
                    Event End: {item?.Event_End_Time}{" "}
                  </p>
                  <div
                    className=" text-blue-400 mt-3 text-center hover:text-blue-700 cursor-pointer"
                    onClick={() => handleInsertEvent(item, i)}
                  >
                    insert into email
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full px-2 py-2" style={{ textAlign: "center" }}>
                No Events yet
              </div>
            )}
            {eventLoading ? (
              <div>
                <em>Loading the Event &nbsp;&nbsp;&nbsp;</em>
                <BeatLoader
                  color={"#220099"}
                  loading={true}
                  cssOverride={override}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div></div>
            )}
            {eventList.length > 0 ? (
              <div className="w-full justify-center">
                <button
                  disabled={currentDays < 29 ? false : true}
                  className="border rounded-md bg-blue-600 text-white px-4 py-2 hover:bg-blue-400"
                  onClick={() => handleLoadMore()}
                >
                  {currentDays < 29 ? "Load More" : "No More Events"}
                </button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : isPhoto ? (
        <div>
          <div
            className="w-full"
            style={{ display: "inline-flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "inline-flex" }}>
              <button
                className="border-2 rounded-md bg-white px-3 py-1 hover:text-blue-500"
                onClick={() => setIsPhoto(!isPhoto)}
              >
                Back to content
              </button>
            </div>
            <div style={{ display: "inline-flex", flexDirection: "flex-end" }}>
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => setShow(!show)}
              >
                Upload Photo
              </span>
            </div>
          </div>
          <div className="mt-2">
            <select
              className=" border border-gray-300 w-full py-2 mt-3 text-gray-700 text-sm rounded-sm  px-4 bg-white"
              //
              onChange={(e) => handleselect(e.target.value)}
            >
              <option value="unsorted">Unsorted</option>
              {albumList?.length > 0 &&
                albumList.map((item, i) => (
                  <option value={item?.Album_Name} key={i}>
                    {item?.Album_Name}
                  </option>
                ))}
            </select>
            <div className="mt-2 grid grid-cols-2 gap-4">
              {activeAlbum &&
              activeAlbum.data &&
              activeAlbum?.data.length > 0 ? (
                activeAlbum?.data.map((item, i) => (
                  <div key={i}>
                    {activeAlbum?.name === "unsorted" ? (
                      <div
                        onClick={() => {
                          setSelectedPhoto(item?.Photo_Path);
                          handleInsertPhoto(item?.Photo_Path);
                        }}
                        className="cursor-pointer h-[140px] w-[140px]"
                      >
                        <img src={item?.Photo_Path} alt="albumphoto" />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setSelectedPhoto(item?.Photo_Path);
                          handleInsertPhoto(item?.Photo_Path);
                        }}
                        className="cursor-pointer h-[140px] w-[140px]"
                      >
                        <img src={item?.Photo_Path} alt="albumphoto" />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div
                  className="w-full px-2 py-2"
                  style={{ textAlign: "center" }}
                >
                  <em>Fetching Photos...</em>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : isEventDigest ? (
        <div className="">
          <button
            className="border-2 rounded-md bg-white px-3 py-1 hover:text-blue-500"
            onClick={() => setIsEventDigest(!isEventDigest)}
          >
            Back to content
          </button>
          <div className="mt-2">
            <p>Select the digest period:</p>
            <div className="mb-2">
              <input
                type="radio"
                checked={digestperiod == "oneweek"}
                name="articlegroup"
                onChange={() => setDigestPeriod("oneweek")}
                className="mx-2"
              />
              <span>One Week</span>
            </div>
            <div className="mb-2">
              <input
                type="radio"
                checked={digestperiod == "twoweeks"}
                name="articlegroup"
                onChange={() => setDigestPeriod("twoweeks")}
                className="mx-2"
              />
              <span>Two Weeks</span>
            </div>
            <div className="mb-2">
              <input
                type="radio"
                checked={digestperiod == "fourweeks"}
                name="articlegroup"
                onChange={() => setDigestPeriod("fourweeks")}
                className="mx-2"
              />
              <span>Four Weeks</span>
            </div>
            <div className="mb-2">
              <input
                type="radio"
                checked={digestperiod == "eightweeks"}
                name="articlegroup"
                onChange={() => setDigestPeriod("eightweeks")}
                className="mx-2"
              />
              <span>Eight Weeks</span>
            </div>
            {/* <div className='mb-2'>
                                <p>Select the digest type:</p>
                            </div>
                            <div className='mb-2'>
                                { activeAlbum?.data.map((item, i) => (
                                    <div>item</div>
                                ))
                                }
                            </div> */}
            <div className="mb-2">
              <button
                className="border-2 rounded-md bg-white px-3 py-1 hover:text-blue-500"
                onClick={() => handleInsertEventDigest(digestperiod)}
              >
                Submit
              </button>
            </div>
            {eventLoading ? (
              <div>
                <em>Loading the Events &nbsp;&nbsp;&nbsp;</em>
                <BeatLoader
                  color={"#220099"}
                  loading={true}
                  cssOverride={override}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-3">
          {inset.map((btn, i) => (
            <div
              className="border rounded-md py-4 cursor-pointer hover:bg-gray-100"
              key={i}
              onClick={() => handleClick(btn)}
            >
              <div className="flex justify-center items-center flex-col">
                {btn.icon}
                <p className="mt-1"> {btn.name}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      {show && (
        <Modal setOpenModal={setShow} title="Upload Photo">
          <label className="text-gray-600"> Select Photo:</label>
          <input
            type="file"
            className="px-4 py-1 my-2 w-full border"
            accept=".jpg,.jpeg,.png"
            onChange={imgFilehandler}
          />

          <div className="flex justify-end">
            <button
              className="px-4 py-2 bg-blue-500 rounded-sm my-3  text-white hover:bg-blue-700"
              onClick={handleUploadSave}
            >
              Save Photo
            </button>
          </div>
        </Modal>
      )}

      <ToastContainer />
    </div>
  );
}

export default InsertContents;
