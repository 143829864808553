const CustomButton = ({
  content,
  icon,
  bgColor,
  clickHandler,
  additionalStyles,
  disabled,
  type,
}) => {
  return (
    <button
      type={type ? type : "button"}
      onClick={clickHandler}
      disabled={disabled}
      className={`${bgColor} transition-all px-2 py-1 md:px-4 md:py-1.5 rounded-sm flex gap-2 items-center disabled:opacity-70 disabled:cursor-not-allowed disabled:pointer-events-none ${additionalStyles}`}
      title={content}
    >
      {icon && <>{icon}</>}
      {content && (
        <div
          className={`${
            icon ? "hidden" : "block"
          } md:block text-sm md:text-base`}
        >
          {content}{" "}
        </div>
      )}
    </button>
  );
};

export default CustomButton;
