import React from 'react'

function Heading({txt,hs,hf}) {
    const HtmlToReactParser = require('html-to-react').Parser;
    const htmlToReactParser = new HtmlToReactParser();
    let t = htmlToReactParser.parse(txt);
    return (
          <div style={{fontSize:`${hs}`,fontFamily:`${hf}`}}>
              {t}
          </div>
    )
}

export default Heading
