import React from "react";

const LogoWithLabel = ({ logoUrl, imageLabel }) => {
  // const [logoWidthToHeightRatio, setLogoWidthToHeightRatio] = useState(1);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   // Create an Image object to load the logo and get its dimensions
  //   const logoImage = new Image();
  //   logoImage.src = logoUrl;

  //   logoImage.onload = () => {
  //     // Calculate the width-to-height ratio of the loaded logo image
  //     const ratio = logoImage.width / logoImage.height;
  //     setLogoWidthToHeightRatio(ratio);
  //     setIsLoading(false);
  //   };
  // }, [logoUrl]);

  // const viewportWidth = window.innerWidth;
  // const rectangularSpaceWidth = viewportWidth * 0.2 * 0.6;
  // const logoWidth = 50 * logoWidthToHeightRatio;

  // const maxLogoWidth = parseInt(Math.min(logoWidth, rectangularSpaceWidth));

  const logoClasses = `object-contain h-[40px] w-full`;

  return (
    <div className="flex flex-col items-center gap-2 w-full">
      {logoUrl && <img src={logoUrl} alt="Logo" className={logoClasses} />}
      <p className="hidden lg:block lg:text-base flex-1 font-medium text-balance">
        {imageLabel}
      </p>
    </div>
  );
};

export default LogoWithLabel;
