import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getReqParamheader } from "../services/apiCall";
import { getItem, setItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";

import PageSnipper from "../components/PageSnipper";
import HomeSplash from "../components/HomeSplash";
import HomeCreateNew from "../components/HomeCreateNew";
import HomeOrgNotVerified from "../components/HomeOrgNotVerified";
import ArticleList from "../components/ArticleList";

//import { ToastContainer, toast } from "react-toastify";
//import e from "cors";

function Home() {
  const navigate = useNavigate();
  const LIMIT_PER_PAGE = 8;
  let currentPage = 1;

  const [token, setToken] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [reachedEndOfList, setReachedEndOfList] = useState(false);
  const [fetchingArticles, setFetchingArticles] = useState(false);

  const [userId, setUser] = useState("");
  const [loading, setLoading] = useState(false);

  const [allOrgList, setAllOrgList] = useState([]);
  const [currentOrgName, setCurrentOrgName] = useState(getItem("currentOrg"));
  const [orgId, setOrg] = useState(null);

  const [unverified, setUnverified] = useState(false);
  const [unverifiedOrg, setUnverifiedOrg] = useState("");

  // const [less, setLess] = useState(true);
  // const path = useLocation().pathname;
  // let desL = htmlToReactParser.parse(data?.Content.slice(0, 200))
  //const [organizationList, setOrganizationList] = useState({});

  useEffect(() => {
    let t = checkAuth();
    setToken(t);
  }, [token, getItem("token")]);

  useEffect(() => {
    let userid = getItem("user_id");

    //check if user id is set
    if (userid && userid.length > 0) {
      setUser(userid);
      fetchInfo();
    }
  }, []);

  //Redirect to dashboard page if valid org id is present and no recent articles to show
  // useEffect(() => {
  //   if (orgId && articleList.length == 0) {
  //     navigate(`/manage/${orgId}`);
  //   }
  // }, [orgId, articleList]);

  const handleView = (id) => {
    navigate(`/view/${id}`);
  };

  async function fetchInfo() {
    try {
      setLoading(true);
      await getCurrentOrg();
      await getAllArticles();
    } finally {
      setLoading(false);
    }
  }

  const getAllArticles = async (offset = 1, limit = LIMIT_PER_PAGE) => {
    setFetchingArticles(true);
    let userid = getItem("user_id");
    let url = `${API_URL_CONSTANT.baseUrl}/allarticlesofusers/${userid}`;
    url += `?Limit=${limit}&Offset=${offset}`;

    let res = await getReqParamheader(url);
    if (res?.status) {
      let articleArr = res?.data?.data;
      if (articleArr.length > 0) {
        setArticleList((list) => [...list, ...articleArr]);
      } else {
        setReachedEndOfList(true);
      }
    } else {
      console.log("Unable to fetch articles..");
    }
    setFetchingArticles(false);
  };

  const loadMoreArticles = () => {
    currentPage++;
    let offset = (currentPage - 1) * LIMIT_PER_PAGE + 1;
    getAllArticles(offset);
  };

  const getCurrentOrg = async () => {
    let userid = getItem("user_id");
    let url = `${API_URL_CONSTANT.baseUrl}/getallOrgsByUserId/${userid}`;
    let orglist = [];

    //let orgid = getItem("org_id");
    //let articleArr = [];
    //let url = `${API_URL_CONSTANT.baseUrl}/getunverifiedList`
    // console.log('get org dedtails response', res?.data?.data)
    // setOrgData(res?.data?.data)

    let {
      status,
      message,
      data: { data: listOfOrg = [] },
    } = await getReqParamheader(url);

    if (status) {
      if (listOfOrg.length > 0) {
        setAllOrgList(listOfOrg);

        //Filter out active org list
        orglist = listOfOrg.filter((org) => org.is_active == true);

        if (orglist.length > 0) {
          //Create JSON object from active org list
          // const orgJson = orglist.reduce((acc, cur) => {
          //   return { ...acc, [cur._id]: cur.OrganizationName };
          // }, {});
          // setOrganizationList(orgJson);

          //Get final org from the active org list
          const finalOrg = orglist[orglist.length - 1];
          setOrg(finalOrg._id);

          if (!currentOrgName) {
            const [firstOrg] = orglist;
            setItem("currentOrg", firstOrg?.OrganizationName);
            setCurrentOrgName(firstOrg?.OrganizationName);
          }
        } else {
          setUnverified(true);
          setUnverifiedOrg(listOfOrg[0]);
        }
      }
    } else {
      console.log("response err", message);
    }
  };

  const checkAuth = () => {
    const session = getItem("token");
    if (session) {
      return true;
    }
    return false;
  };

  const createOrganization = () => {
    navigate("/create-org");
  };

  const redirectToCreateArticlePage = () => {
    navigate(`manage/${orgId}/article/create`);
  };

  const redirectToDashboard = () => {
    navigate(`manage/${orgId}`);
  };

  let homeTemplate = null;
  if (allOrgList.length == 0) {
    homeTemplate = (
      <HomeCreateNew
        buttonText="Create Organization"
        handleClicked={createOrganization}
      >
        There is no Organization created by you yet. You can choose to create
        one.
      </HomeCreateNew>
    );
  } else if (allOrgList.length > 0 && unverified) {
    homeTemplate = (
      <HomeOrgNotVerified orgName={unverifiedOrg.OrganizationName} />
    );
  } else if (currentOrgName && !unverified) {
    homeTemplate = articleList.length ? (
      <ArticleList
        articles={articleList}
        orgName={currentOrgName}
        clicked={handleView}
        fetching={fetchingArticles}
        onLoadMore={loadMoreArticles}
        hasReachedEndOfList={reachedEndOfList}
      />
    ) : (
      <HomeCreateNew
        buttonText="Create New Article"
        secondaryButtonText="Go To Dashboard"
        handleClicked={redirectToCreateArticlePage}
        secondaryButtonClicked={redirectToDashboard}
      >
        <p>It seems there are no articles available at the moment.</p>
        <p className="mt-2">
          Be the first to create an article and share your thoughts with the
          community!
        </p>
      </HomeCreateNew>
    );
  }

  return (
    <div className="">
      {token ? (
        <>
          {loading ? (
            <PageSnipper loading={loading} />
          ) : (
            <div className="home-template-container bg-gray-100 px-8 py-3">
              {homeTemplate}
            </div>
          )}
        </>
      ) : (
        <HomeSplash />
      )}
    </div>
  );
}

export default Home;
