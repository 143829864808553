export const ROUTER_URL_CONSTANT = {
  Home: "/",
  Signup: "/signup",
  Login: "/login",
  Forget: "/reset_password",
  // ChangePassword: "/change_password",
  ResetPassword: "/reset_password?token=:id",
  Dashboard: "/dashboard",
  CreateOrg: "/create-org",
  ProfileSetting: "/user/setting",
  EditProfile: "user/setting/edit-profile",
  ChangePassword: "user/setting/change-password",
  Manage: "/manage/:orgId",
  Articlev: "/view/:id",
  Eventv: "/event/view/:id",
  Emailv: "/email/view/:id",
  EmailUnsub: "/email-unsub/:orgid/:cid",

  Email: "/manage/:orgId/email",
  EmailStats: "/manage/:orgId/email/:EmailId/stats",
  CreateEmail: "/manage/:orgId/email/create",
  EditEmail: "/manage/:orgId/email/:EmailId",

  Contact: "/manage/:orgId/contact",
  CustomContact: "/manage/:orgId/contact/custom",
  CreateCustomContact: "/manage/:orgId/contact/custom/create",
  EditCustomContact: "/manage/:orgId/contact/custom/:cId",

  Team: "/manage/:orgId/team",
  EditTeam: "/manage/:orgId/team/:teamId",

  Photo: "/manage/:orgId/photo",

  Events: "/manage/:orgId/events",
  CreateEvent: "/manage/:orgId/events/create",

  Article: "/manage/:orgId/article",
  CreateArticle: "/manage/:orgId/article/create",
  EditArticle: "/manage/:orgId/article/:artId",

  Widget: "/manage/:orgId/widgets",
  CreateWidget: "/manage/:orgId/widgets/create",
  EditWidget: "/manage/:orgId/widgets/:widgetId",
  ViewWidget: "/widget/:widgetId",

  OrgApproval: "/approval",
};
