import React from "react";

const HomeFeaturesList = () => {
  return (
    <div className="h-fit overflow-hidden flex flex-col md:flex-row text-xl md:text-2xl bg-orange-500 text-white px-6 md:px-12 xl:px-16 items-center py-4 md:py-10">
      <div
        className="h-full w-full md:w-3/5 text-lg md:text-2xl 2xl:text-3xl leading-relaxed
2xl:leading-10"
      >
        <h1 className="font-bold">All The Tools You Need In One Place</h1>
        <p className="mt-1  w-[80%] ">
          We help you accomplish your organization's day-to-day goals, with
          tools that help you:
        </p>
        <ul className="mt-8  list-disc">
          <li>Manage Multiple Lists of Contacts</li>
          <li>Create Professional Looking Articles and Events</li>
          <li>Send Emails & Newsletters</li>
          <li>Connects to your Social Media</li>
          <p>
            <em>And Much More...</em>
          </p>
        </ul>
      </div>
      <div className="h-full w-2/5 hidden md:block">
        <img
          className="w-full md:w-3/5 object-cover -rotate-12"
          // src="images/clipboard.png"
          src={process.env.PUBLIC_URL + "/images/clipboard.png"}
          alt="Task List"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default HomeFeaturesList;
