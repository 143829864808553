import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseTable from "../components/BaseTable";
import {
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
  postReqParamheaderFile,
} from "../services/apiCall";
import { getItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";
import CustomButton from "../components/CustomButton";
import Pagination from "../components/Pagination";
import { MailPlus, Frown, Search } from "lucide-react";
import { debounce } from "lodash";

function EmailHome() {
  const [emailList, setEmailList] = useState([]);
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalEmails, setTotalEmails] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalRowsPerPage = 7; // Number of rows per page

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
  }, []);

  useEffect(() => {
    if (orgId) {
      getAllEmailList(1);
    }
  }, [orgId]);

  const getAllEmailList = (page = 1) => {
    setLoading(true);
    let offset = (page - 1) * totalRowsPerPage + 1;
    let url = `${API_URL_CONSTANT.baseUrl}/emaildetails/${orgId}/${userId}?Limit=${totalRowsPerPage}&Offset=${offset}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log("get email list response", res?.data);
          setEmailList(res?.data?.data);
          setTotalEmails(res?.data?.count);
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const totalPages = Math.ceil(totalEmails / totalRowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    searchTerm !== "" ? handleSearch(page) : getAllEmailList(page);
  };

  const handleDelete = (id) => {
    let a = window.confirm("Are you sure you want to delete this email?");
    if (a) {
      setLoading(true);
      let url = `${API_URL_CONSTANT.baseUrl}/deleteEmails/${id}/${orgId}/${userId}`;
      deleteReqParamheader(url)
        .then((res) => {
          if (res?.status) {
            console.log("delete email by id  response", res?.data);
            getAllEmailList(1);
            setLoading(false);
            toast.success(res?.data?.message);
          } else {
            console.log("response err", res?.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const handleRestore = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/restoreEmails/${id}/${orgId}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("restore email by id response", res?.data);
          getAllEmailList(1);

          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleResend = (id) => {
    getByIdEmail(id);
  };
  const getByIdEmail = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/emaildetailsbyId/${id}/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log(
            "get email template details by id response",
            res?.data?.data?.sent_email_recipents
          );
          let email = res?.data?.data?.sent_email_recipents.join(",");
          // console.log(email)
          if (email) {
            let data = {};
            data.sent_email_recipents = email;
            sendEmailToUser(data, id);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const sendEmailToUser = (data, id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/sentMailtoaddress/${id}/${orgId}/${userId}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("sent email response", res?.data);
          toast.success(res?.data?.message);
        } else {
          //console.log('response err', res?.message)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEdit = (id) => {
    // console.log('handle edit::::', id, path)
    navigate(`${path}/${id}`);
    // alert('Edit Email Integration in Progress...')
    // getByIdEmail(id)
  };
  const handleView = (id) => {
    // navigate(`${path}/${id}`)
    alert("View Template in progress...");
  };

  const handleViewStats = (id) => {
    navigate(`${path}/${id}/stats`);
  };

  const handleCreateMail = () => {
    navigate(`${path}/create`);
  };

  useEffect(() => {
    if (orgId) {
      debouncedGetAllEmails(searchTerm);
    }
  }, [searchTerm, orgId]);

  const debouncedGetAllEmails = debounce((searchTerm) => {
    if (searchTerm === "") {
      getAllEmailList(1);
    }
  }, 500);

  const handleSearch = (page = 1) => {
    setLoading(true);
    let offset = (page - 1) * totalRowsPerPage;
    let url = `${
      API_URL_CONSTANT.baseUrl
    }/searchEmailBySubject/${orgId}/${userId}?Limit=${totalRowsPerPage}&Offset=${offset}&q=${encodeURIComponent(
      searchTerm
    )}`;
    console.log(url);
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setEmailList(res?.data?.data);
          setTotalEmails(res?.data?.count);
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between items-center mb-3">
            <p className="text-sm md:text-lg lg:text-xl font-medium">
              Emails ({totalEmails}){" "}
            </p>

            <div className="flex gap-4 items-center">
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Search Emails by Subject..."
                  className="w-[25vw] px-2 py-1 md:px-3 md:py-1.5 text-sm md:text-base placeholder-gray-500 pe-8 md:pe-24 rounded-l-sm outline-1 focus:outline-blue-500 active:outline-blue-500 transition-all "
                  value={searchTerm}
                  onKeyDown={(e) => e.key === "Enter" && handleSearch(1)}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <CustomButton
                  icon={<Search size={18} color="#fff" />}
                  content={"Search"}
                  bgColor={"bg-blue-600 "}
                  clickHandler={() => handleSearch(1)}
                  additionalStyles={"text-white hover:bg-blue-700 rounded-r-sm"}
                  disabled={searchTerm === ""}
                />
              </div>
              <CustomButton
                icon={<MailPlus size={20} />}
                bgColor={"bg-blue-600"}
                content={"Compose Email"}
                clickHandler={handleCreateMail}
                additionalStyles={"text-white hover:bg-blue-700 "}
              />
            </div>
          </div>
          {emailList?.length > 0 ? (
            <div>
              <BaseTable
                header={["Subject", "Email Status", "Last Accessed", "Actions"]}
                data={emailList}
                remove={handleDelete}
                edit={handleEdit}
                restore={handleRestore}
                resend={handleResend}
                view={handleView}
                viewStats={handleViewStats}
                type="email"
              />
            </div>
          ) : (
            <div className="w-full h-[60vh] flex justify-center flex-col items-center gap-8">
              <Frown size={48} color="#6a6868" strokeWidth={1.25} />
              <p className="text-lg text-gray-600 font-medium">
                No Emails found
              </p>
            </div>
          )}

          {totalEmails > totalRowsPerPage && (
            <Pagination
              total={totalPages}
              current={currentPage}
              onPageChange={(page) => handlePageChange(page)}
            />
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default EmailHome;
