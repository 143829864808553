import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { getItem } from "../utils/storage";

let org_id = getItem("org_id");
let user_id = getItem("user_id");

const custom_config = {
  extraPlugins: [MyCustomUploadAdapterPlugin],
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "insertTable",
      "|",
      "imageUpload",
      "|",
      "undo",
      "redo",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
};

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    // alert('hiii')
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    //this.url = ${API_URL_CONSTANT.baseUrl}/uploadPhotos/${org_id}/${user_id}
    //uploading only max width 600px images here
    this.url = `${API_URL_CONSTANT.baseUrl}/uploadcompressPhotos/${org_id}/${user_id}`;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open("POST", this.url, true);
    xhr.responseType = "json";
    // xhr.setRequestHeader('Content-Type', 'multipart/form-data')
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Authorization", `Bearer ${getItem("token")}`);
  }

  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${loader.file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      console.log("res::::;;", response);
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: response?.data?.Photo_Path,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then((result) => {
      data.append("files", result);
      this.xhr.send(data);
    });
  }
}

function ArticleEditor({ txt, handleTxt }) {
  org_id = getItem("org_id");
  user_id = getItem("user_id");
  return (
    <div className="w-full text-justify">
      <CKEditor
        required
        className="text-justify"
        editor={ClassicEditor}
        config={custom_config}
        data={txt}
        onChange={(event, editor) => {
          let data = editor.getData();
          handleTxt(data);
        }}
      />
      {/* {txt} */}
    </div>
  );
}

export default ArticleEditor;
