import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./TextEditter.css";

//import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

const custom_config = {
  // plugins: [Base64UploadAdapter ],
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "insertTable",
      "|",
      // 'imageUpload',
      "undo",
      "redo",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
};

function TextEditter({ txt, handleTxt }) {
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  return (
    <div className="w-full custom-editor">
      <CKEditor
        required
        editor={ClassicEditor}
        config={custom_config}
        data={txt}
        onChange={(event, editor) => {
          let data = editor.getData();
          handleTxt(data);
          // console.log(data);
        }}
      />
    </div>
  );
}

export default TextEditter;
