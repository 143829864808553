import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { postReqParam, postReqParamheaderFile } from '../services/apiCall'
import { useLocation, useNavigate } from "react-router-dom";
import { setItem } from '../utils/storage';
import { API_URL_CONSTANT } from '../constants/apiConstant';
import { CheckMark } from '../assests/icons/email/BaseIcon';

function ResetPassword() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [token, setToken] = useState('')
    const [isEmail, setIsEmail] = useState(true)
    const [isSubmit, setIsSubmit] = useState(false)
    const [vEmail, setVEmail] = useState({ msg: '', v: false })
    const [passwordType, setPasswordType] = useState("password");
    const navigate = useNavigate();
    const path = useLocation()

    useEffect(() => {
        let t = path?.search.split('=')[1]
        if (t) {
            setToken(t)
            setIsEmail(!isEmail)
        }
        console.log(t)

    }, [path])


    const handleSubmit = (e) => {
        e.preventDefault()
        //
        let data = {}
        if (isEmail) {
            data.email = email
            forget(data)
        }
        else {
            data.password = password
            reset(data)
        }
        // navigate({
        //     pathname: '/reset_password',
        //     search: '?token=bfghfhdh',
        // });

    }

    const emailValidation = (val) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(val)) {
            setVEmail({ ...vEmail, v: true, msg: 'Please provide a valid email' })
            return false;
        }
        else {
            setVEmail({ ...vEmail, v: false, msg: '' })
            return true
        }

    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const forget = (data) => {
        let url = `${API_URL_CONSTANT.baseUrl}/forget_password`
        postReqParamheaderFile(url, data)
            .then((res) => {
                if (res?.status) {
                    console.log('response', res?.data)
                    // setItem('token', res?.data?.data?.token)
                    setIsSubmit(!isSubmit)
                    toast.success(res?.data?.message)
                }
                else {
                    console.log('response err', res?.message)

                }
            })
            .catch(err => {
                console.log('err', err)
                toast.error(err?.response?.data?.message)
                // toast.error('Something went wrong !')
            })

    }
    const reset = (data) => {
        let url = `${API_URL_CONSTANT.baseUrl}/reset_password?token=${token}`
        postReqParam(url, data)
            .then((res) => {
                if (res?.status) {
                    console.log('response', res?.data)
                    // setItem('token', res?.data?.data?.token)
                    toast.success('Reset Password Success ..!')
                    navigate('/login')
                }
                else {
                    console.log('response err', res?.message)

                }
            })
            .catch(err => {
                console.log('err', err)
                toast.error(err?.response?.data?.message)
                // toast.error('Something went wrong !')
            })

    }
    return (
        <div className="w-full mt-5 flex justify-center items-center flex-col">
            <form className="bg-white shadow-md rounded px-8 py-10 w-[80%] md:w-[30%]" onSubmit={handleSubmit}>
                {
                    isEmail ?
                        <>
                            {
                                !isSubmit ?
                                    <div>
                                        <div className='mb-4'>
                                            <label className="block text-gray-700 text-sm mb-2">
                                                User Email
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700
                         leading-tight focus:shadow-outline"
                                                type="email" placeholder="Email" required
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                    emailValidation(e.target.value)
                                                }
                                                } />

                                            <p className={vEmail?.v ? 'block text-red-700 font-light' : "hidden"}>

                                                {
                                                    vEmail?.msg
                                                }

                                            </p>

                                        </div>
                                        <div className="flex items-center justify-end mt-8">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 
                         rounded focus:outline-none focus:shadow-outline" type="submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className='text-gray-700 h-[50vh] flex justify-center items-center flex-col text-xl text-center'>
                                        <p>We have sent a reset password email to : </p>
                                        <p className="text-blue-500 font-bold">{email} </p>
                                        <p>Status: sent</p>
                                        <p>If you don't see reset password email  <br /> please check SPAM folder</p>
                                    </div>
                            }


                        </>

                        :
                        <>
                            <div className="mb-2">
                                <label className="block text-gray-700 text-sm  mb-2" >
                                   New Password
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3
                         text-gray-700 leading-tight focus:shadow-outline" type={passwordType}
                                    placeholder="*********" required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className='flex mb-3'>

                                <input type='checkbox' value='password' checked={passwordType !== "password"}
                                    onChange={(e) => togglePassword()}
                                />

                                <label className="block text-gray-700 text-sm ml-3" >
                                    {
                                        passwordType === "password" ?
                                            ' Show Password'
                                            :
                                            'Hide Password'
                                    }

                                </label>
                            </div>
                            <div className="block text-gray-700 text-sm  mb-2">
                                <p className='pb-3'>All checkmarks must turn green, password must have:</p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password?.length > 7} />
                                    <span> At least 8 characters </span>
                                </p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password && password[0] === password[0]?.toUpperCase()} />
                                    <span> Fitst letter should be uppercase  </span>
                                </p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password?.match(/[a-z]/)} />
                                    <span>
                                        At least 1 lowercase letter
                                    </span>

                                </p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password?.match(/[0-9]/) && password?.match(/[!@#$%^&*]/)} />
                                    <span>
                                        At least 1 number and special character
                                    </span>
                                </p>
                            </div>
                            <div className="flex items-center justify-end mt-8">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 
                         rounded focus:outline-none focus:shadow-outline" type="submit"
                                >
                                    Submit
                                </button>
                            </div>

                        </>


                }



                <ToastContainer />
            </form>
        </div>
    )
}

export default ResetPassword
