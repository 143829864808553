export function setItem(key, value) {
  localStorage.setItem(key,value)
}

export function getItem(key) {
  const value = localStorage.getItem(key)

  if (value) {
    return value
  }
  return null
}

export function deleteItem(key) {
  localStorage.removeItem(key)
}

export function clear() {
  localStorage.clear()
}
