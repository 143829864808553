import React from "react";
import EmailTemplate from "../../components/EmailTemplate";

function CreateEmail() {
  return (
    <>
      <EmailTemplate />
    </>
  );
}

export default CreateEmail;
