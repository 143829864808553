import React, { useState, useEffect, useRef } from "react";

function OrgLogo({ url, logo, orgName, save, id, logow, logoh }) {
  const [imgHeight, setImgHeight] = useState("0");
  const [imgWidth, setImgWidth] = useState("0");
  const containerRef = useRef(null);

  const processLogoImg = async (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = logo;
      img.onload = () => {
        let aratio = (img.height / img.width).toFixed(2);
        let w = img.width > 600 ? 600 : img.width;
        let h = img.width > 600 ? 600 * aratio : img.height;
        setImgHeight(h.toString());
        setImgWidth(w.toString());
        if (typeof save === "function") {
          save(id, w.toString(), h.toString());
        }
        resolve({ w, h });
      };
    });
  };

  useEffect(() => {
    //set image params here
    //if(imgWidth === '0'){
    if (containerRef.current) {
      async function fetchImg() {
        const response = await processLogoImg();
      }
      fetchImg();
      /*let img = new Image();
            img.src = logo;
            img.onload = () => {
                let aratio = (img.height/img.width).toFixed(2);
                let w = img.width > 600 ? 600 : img.width;
                let h = img.width > 600 ? (600*aratio) : img.height;
                setImgHeight(h.toString());
                setImgWidth(w.toString());
                save(id, w.toString(), h.toString());
            }*/
    }
  }, []);

  return (
    <div>
      {logo ? (
        logow && logoh ? (
          <table border="0" cellSpacing="0" width="100%">
            <tr>
              <td width="600" align="center">
                <a
                  href={url}
                  target="_blank"
                  style={{ textAlign: "-webkit-center" }}
                  width="600"
                  rel="noopener noreferrer"
                >
                  <img
                    ref={containerRef}
                    src={logo}
                    alt="logo"
                    width={logow}
                    height={logoh}
                    style={{
                      width: logow + "px",
                      height: logoh + "px",
                      minWidth: "70px",
                      maxWidth: "600px",
                      display: "block",
                      margin: "0px auto",
                    }}
                  />
                </a>
              </td>
            </tr>
          </table>
        ) : (
          <table border="0" cellSpacing="0" width="100%">
            <tr>
              <td width="600" align="center">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textAlign: "-webkit-center" }}
                  width="600"
                >
                  <img
                    ref={containerRef}
                    src={logo}
                    width={imgWidth}
                    height={imgHeight}
                    alt="logo"
                    style={{
                      width: imgWidth + "px",
                      height: "auto",
                      minWidth: "100px",
                      maxWidth: "600px",
                      display: "block",
                      margin: "0px auto",
                    }}
                  />
                </a>
              </td>
            </tr>
          </table>
        )
      ) : (
        <a
          style={{
            width: "100%",
            textAlign: "-webkit-center",
            display: "block",
          }}
        >
          <div
            style={{
              height: "70px",
              width: "70px",
              backgroundColor: "#c0c0c0",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>
              {orgName?.split("")[0]?.toUpperCase() || ""}{" "}
              {orgName?.split(" ")[1]?.split("")[0]?.toUpperCase() || ""}
            </span>
          </div>
        </a>
      )}
    </div>
  );
}

export default OrgLogo;
