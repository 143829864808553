import React, { useState, useEffect, useRef } from "react";
import LogoWithLabel from "../LogoWithLabel";
import { getReqParamheader } from "../../services/apiCall";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getItem, setItem } from "../../utils/storage";

function DefaultLogo({ url, type, path, save, currentid, logow, logoh }) {
  const [orgData, setOrgData] = useState({});
  const [data, setData] = useState({});
  const [orgId, setOrgId] = useState("");
  const [orgName, setOrgDName] = useState("");
  const [imgHeight, setImgHeight] = useState("0"); //Low 100px height
  const [imgWidth, setImgWidth] = useState("0"); //Low 100px width
  let orgname = getItem("currentOrg");
  let username = getItem("currentuser");
  let user = getItem("user");
  user = JSON.parse(user);
  const containerRef = useRef(null);

  useEffect(() => {
    if (path?.split("/")[1] === "user") {
      setOrgDName(username);
    } else {
      setOrgDName(orgname);
    }
    let id = path?.split("/")[2];
    if (id) {
      if (id === "view") {
        //email view template
      } else {
        setOrgId(id);
      }
    }

    //set image params here
    // if(imgWidth === '0'){
    if (containerRef.current) {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        let aratio = (img.height / img.width).toFixed(2);
        let w = img.width > 600 ? 600 : img.width;
        let h = img.width > 600 ? 600 * aratio : img.height;
        setImgHeight(h.toString());
        setImgWidth(w.toString());
        if (currentid && typeof save === "function") {
          save(currentid, w.toString(), h.toString());
        }
      };
    }
  }, [orgId, orgname, path]);

  useEffect(() => {
    if (orgId) {
      let id = getItem("user_id");
      getUserById(id);
      getCurrentOrg(orgId);
    }
  }, [orgId]);

  // useEffect(() => {
  //     if(imgWidth !== "0"){
  //         save();
  //     }
  // }, [imgWidth]);

  const getCurrentOrg = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Organization_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log("get org dedtails response", res?.data);
          setOrgData(res?.data?.data);
          //Reusing the API here
          setItem("currentOrgLogo", res?.data?.data.logo);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getUserById = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/user/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log("response get user by id ", res?.data?.data);
          // setItem('user', JSON.stringify(res?.data?.data))
          // setItem('currentuser',res?.data?.data?.firstName)
          setData(res?.data?.data);
        } else {
          console.log("response err", res?.message);
          // toast.error(`${res?.message}`)
        }
      })
      .catch((err) => {
        console.log("err user by id ", err);
      });
  };

  const logoUrl =
    orgData?.logo && path?.split("/")[1] !== "user"
      ? orgData.logo
      : data?.profile_photo;

  return (
    <>
      {type === "Photo" ? (
        logow && logoh ? (
          <table border="0" cellSpacing="0" width="100%">
            <tbody>
              <tr>
                <td width="600" align="center">
                  <img
                    ref={containerRef}
                    src={url}
                    width={logow}
                    height={logoh}
                    style={{
                      width: logow + "px",
                      height: logoh + "px",
                      minWidth: "70px",
                      maxWidth: "600px",
                      display: "block",
                      margin: "0px auto",
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table border="0" cellSpacing="0" width="100%">
            <tbody>
              <tr>
                <td width="600" align="center">
                  <img
                    ref={containerRef}
                    src={url}
                    width={imgWidth}
                    height={imgHeight}
                    style={{
                      width: { imgWidth } + "px",
                      height: { imgHeight } + "px",
                      minWidth: "70px",
                      maxWidth: "600px",
                      display: "block",
                      margin: "0px auto",
                    }}
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        )
      ) : (
        <LogoWithLabel logoUrl={logoUrl} imageLabel={orgName} />
      )}
    </>
  );
}

export default DefaultLogo;
