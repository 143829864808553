import React, { useState, useRef, useEffect } from "react";
import Article from "./Article";

import { Spin } from "antd";

export default function ArticleList({
  articles,
  orgName,
  clicked,
  fetching,
  onLoadMore,
  hasReachedEndOfList = false,
}) {
  const [isVisibleDummyDiv, setDummyDivVisibility] = useState(false);

  const ref = useRef(null);

  const options = {
    root: null,
    threshold: [0.75],
  };
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      onLoadMore();
    }
  }, options);

  useEffect(() => {
    if (articles.length > 0 && !fetching && !hasReachedEndOfList) {
      setDummyDivVisibility(true);
    } else if (!fetching && hasReachedEndOfList) {
      setDummyDivVisibility(false);
    }
  }, [articles, hasReachedEndOfList]);

  useEffect(() => {
    if (isVisibleDummyDiv) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [isVisibleDummyDiv]);

  return (
    <>
      <p className="text-xl font-semibold text-gray-600"> Recent Articles</p>
      <div className="w-full mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {articles.map((article) => (
          <Article
            key={article.id}
            item={article}
            orgname={orgName}
            handleClickArticle={clicked}
          />
        ))}
      </div>
      {isVisibleDummyDiv && (
        <div ref={ref} className="flex justify-center items-center h-32">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}
