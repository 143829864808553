import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Spin } from "antd";

import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getReqParamheader } from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import MetaDes from "../../components/MetaDes";
import BaseBackButton from "../../components/BaseBackButton";

function ViewEvent() {
  const [articleId, setArticleId] = useState("");
  const [data, setData] = useState({});
  const [fetching, setFetching] = useState(true);
  const path = useLocation().pathname;
  const [org, setOrg] = useState("");
  const [user, setUser] = useState("");
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  let des = htmlToReactParser.parse(data?.Event_Description);
  let currentorg = getItem("currentOrg");
  let currentOrgid = getItem("org_id");
  const navigate = useNavigate();

  useEffect(() => {
    let article = path.split("/")[3];
    let userid = getItem("user_id");
    let orgid = getItem("org_id");
    if (userid && orgid) {
      setOrg(orgid);
      setUser(userid);
    }
    setArticleId(article);
  }, []);

  useEffect(() => {
    if (articleId && org && user) {
      getEventData();
    }
  }, [articleId]);

  const getEventData = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/eventDetails/${org}/${articleId}`;
    setFetching(true);
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get event list response", res?.data);
          des = htmlToReactParser.parse(res?.data?.data?.Event_Description);
          setData(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const handleOrg = () => {
    navigate(`/manage/${currentOrgid}`);
  };

  const navigateToEventList = () => {
    navigate(`/manage/${currentOrgid}/events`);
  };

  return (
    <Spin tip="Loading" size="large" spinning={fetching}>
      <div className="flex justify-center min-h-screen bg-gray-100 ">
        {!fetching ? (
          <div
            className="w-[50%] px-8 py-4 mt-4 bg-white"
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
              marginBottom: "3%",
            }}
          >
            <Breadcrumb
              items={[
                {
                  title: <button onClick={navigateToEventList}>Events</button>,
                },
                {
                  title: data?.Event_Name,
                },
              ]}
            />
            <div className="w-full mt-0 flex justify-center">
              {data?.Featured_Photo && (
                <img
                  src={data?.Featured_Photo}
                  className="max-h-[600px] max-w-[600px] px-4 py-4"
                />
              )}
              <MetaDes
                image={data?.Featured_Photo}
                title={data?.Event_Name}
                description={des}
              />
            </div>
            <p className="text-2xl">{data?.Event_Name} </p>
            <div className="flex justify-between items-start  mt-2">
              <div className="text-gray-600 text-1xl">
                <p
                  className="text-gray-600 cursor-pointer hover:text-blue-800"
                  onClick={handleOrg}
                >
                  <span className="text-sm text-gray-600 mr-1">
                    Hosted By : {data?.Event_Organizer}
                  </span>
                  {currentorg}
                </p>

                <p className="text-sm text-gray-600">
                  {" "}
                  Start event: {data?.Event_Start_Date}{" "}
                </p>
                <p className="text-sm text-gray-600">
                  {" "}
                  End event: {data?.Event_End_Time}{" "}
                </p>
              </div>

              <div className="text-gray-600 text-1xl flex items-center gap-3">
                Share
                <FacebookShareButton
                  url={`https://smorgborg.org/${path}`}
                  // quote={'heyyy'}
                  // hashtag="#camperstribe"
                >
                  <FacebookIcon size={32} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`https://smorgborg.org/${path}`}
                  // title={"CampersTribe - World is yours to explore"}
                  // hashtag="#camperstribe"
                >
                  <TwitterIcon size={32} />
                </TwitterShareButton>
                {/* <img src={process.env.PUBLIC_URL + "/images/asset_1.png"} /> */}
              </div>
            </div>
            <p className="text-2xl pt-3"> Event Details</p>
            <div className="w-full">{des}</div>
            <div className="w-full mt-10">
              <BaseBackButton
                type="button"
                text="Go Back to Event List"
                clickHandler={navigateToEventList}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Spin>
  );
}

export default ViewEvent;
