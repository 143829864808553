import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function ModiificationCard({
  title,
  remove,
  index,
  up,
  down,
  n,
  type,
  style,
  setStyle,
  saveBtn,
  readmore,
  currentread,
  elementid,
  close,
}) {
  const handleReadMore = (value) => {
    readmore(elementid, value);
    saveBtn();
  };

  return (
    <div className="border rounded-lg bg-white py-2 shadow-xl w-[300px]">
      <div className="px-4 flex justify-between items-center">
        <span>{title} Options</span>
        <FontAwesomeIcon
          icon={faXmark}
          className="cursor-pointer"
          onClick={close}
        />
      </div>
      <div className="border-b border-gray-200 w-full pb-2"> </div>
      <div className="px-4 py-2">
        {type === "image" ? (
          <>
            <p> Link:</p>
            <input
              type="text"
              className="w-full my-1 border rounded-sm bg-white px-2 py-2"
              value={style?.logoUrl}
              onInput={(e) =>
                setStyle({ ...style, logoUrl: e.target.value }, index)
              }
              onBlur={saveBtn}
            />
          </>
        ) : type === "btn" ? (
          <div>
            <div className="mb-4">
              <p> Text:</p>
              <input
                type="text"
                className="w-full my-1 border rounded-sm bg-white px-2 py-1"
                value={style?.btnText}
                onInput={(e) =>
                  setStyle({ ...style, btnText: e.target.value }, index)
                }
                onBlur={saveBtn}
              />
            </div>
            <div className="mb-4">
              <p className="mb-1">Button Size:</p>
              <select
                className=" border border-gray-300 text-gray-700 text-sm rounded-sm w-full px-2 py-2 bg-white"
                onChange={(e) =>
                  setStyle({ ...style, btnSize: e.target.value }, index)
                }
              >
                <option value="14px">Small</option>
                <option value="16px">Medium</option>
                <option value="20px">Large</option>
              </select>
            </div>
            <div className="mb-4">
              <p className="mb-1">Alignment:</p>
              <select
                className=" border border-gray-300 text-gray-700 text-sm rounded-sm w-full px-2 py-2 bg-white"
                onChange={(e) =>
                  setStyle({ ...style, btnAlign: e.target.value }, index)
                }
              >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </div>
            <div>
              <p> Link:</p>
              <input
                type="text"
                className="w-full my-1 border rounded-sm bg-white px-2 py-2"
                value={style?.btnLink}
                onInput={(e) =>
                  setStyle({ ...style, btnLink: e.target.value }, index)
                }
                onBlur={saveBtn}
              />
            </div>
          </div>
        ) : type === "artical" || type == "event" ? (
          <div>
            <p className="mb-2">Show how your Article appears in Email?</p>
            <div className="mb-2">
              <input
                type="radio"
                checked={currentread}
                name="articlegroup"
                onChange={() => handleReadMore(true)}
                className="mx-2"
              />
              <span>With 'Read More'</span>
            </div>
            <div className="mb-2">
              <input
                type="radio"
                checked={!currentread}
                name="articlegroup"
                onChange={() => handleReadMore(false)}
                className="mx-2"
              />
              <span>Show Full Article</span>
            </div>
            <hr />
          </div>
        ) : type === "Photo" ? (
          <>
            <p> Link:</p>
            <input
              type="text"
              className="w-full my-1 border rounded-sm bg-white px-2 py-2"
              value={style?.photoUrl}
              onInput={(e) =>
                setStyle({ ...style, photoUrl: e.target.value }, index)
              }
              onBlur={saveBtn}
            />
          </>
        ) : null}

        <div className="flex justify-around items-center pt-2">
          <button
            className={
              index === 0
                ? "hidden"
                : "flex border rounded-md px-2 py-1 hover:bg-gray-100"
            }
            onClick={() => up(index)}
          >
            Move Up
          </button>
          <button
            className={
              index === n - 1
                ? "hidden"
                : "flex border rounded-md px-2 py-1 hover:bg-gray-100"
            }
            onClick={() => down(index)}
          >
            Move Down
          </button>
          <button
            className="border rounded-md px-2 py-1 hover:bg-gray-100"
            onClick={() => remove(index)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModiificationCard;
