import React from "react";

import { Link } from "react-router-dom";
import HomeIntroBanner from "./HomeIntroBanner";
import HomeFeaturesList from "./HomeFeaturesList";
import HomeCalender from "./HomeCalender";
import Hero from "./Hero";
import HomeContactUs from "./HomeContactUs";

function HomeSplash() {
  return (
    <div
      style={{ width: "100%", overflow: "hidden" }}
      className="flex flex-col"
    >
      <HomeIntroBanner />
      <HomeFeaturesList />
      <HomeCalender />
      <Hero />
      <HomeContactUs />
      <footer className="w-full text-center p-6 border-t border-slate-200">
        <Link to="/tos">{"Terms of Service"}</Link>
        {" | "}
        <Link to="/privacy">{"Privacy Policy"}</Link>
        {" | "}
        <Link to="/contact">{"Contact Us"}</Link>
      </footer>
    </div>
  );
}

export default HomeSplash;
