import { getItem } from "../utils/storage"
import axios from 'axios'

export function getReq(url) {
  return axios.get(url)
}

export function getReqParam(url) {
  const authHeader = {
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Authorization: `Bearer ${getItem('token')}`,
  }
  return axios({
    method: 'get',
    url,
    headers: authHeader,
    responseType: 'blob'
  })
}

export function getReqParamheader(url) {
  const authHeader = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getItem('token')}`,
  }
  return axios({
    method: 'get',
    url,
    headers: authHeader,
  })
}

export function getReqParamheaderUnAuth(url) {
  const authHeader = {
    'Content-Type': 'application/json'
  }
  return axios({
    method: 'get',
    url,
    headers: authHeader,
  })
}

export function postReqParam(url, args) {
  const authHeader = {
    'Content-Type': 'application/json',
  }
  return axios({
    method: 'post',
    url,
    headers: authHeader,
    data: args,
  })
}
export function postReqParamheader(url, args) {
  const authHeader = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getItem('token')}`,
  }
  return axios({
    method: 'post',
    url,
    headers: authHeader,
    data: args,
  })
}
export function postReqParamheaderFile(url, args) {
  const authHeader = {
    'Content-Type': 'multipart/form-data"',
    Authorization: `Bearer ${getItem('token')}`,
  }
  return axios({
    method: 'post',
    url,
    headers: authHeader,
    data: args,
  })
}
export function putReqParamheaderFile(url, args) {
  const authHeader = {
    'Content-Type': 'multipart/form-data"',
    Authorization: `Bearer ${getItem('token')}`,
  }
  return axios({
    method: 'patch',
    url,
    headers: authHeader,
    data: args,
  })
}
export function patchReqParam(url, args) {
   const authHeader = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getItem('token')}`,
  }
  return axios({
    method: 'patch',
    url,
    headers: authHeader,
    data: args,
  })
}
export function putReqParam(url, args) {
  const authHeader = {
   'Content-Type': 'application/json',
   Authorization: `Bearer ${getItem('token')}`,
 }
 return axios({
   method: 'put',
   url,
   headers: authHeader,
   data: args,
 })
}
export function deleteReqParam(url) {
  return axios.delete(url)
}

export function deleteReqParamheader(url) {
  const authHeader = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getItem('token')}`,
  }
  return axios({
    method: 'delete',
    url,
    headers: authHeader,
  })
}
