import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StatisticsWidget = ({ label, icon, count }) => {
  return (
    <div className="flex items-start flex-col gap-3 bg-white p-4 rounded-md shadow-md">
      <p className="text-xl font-medium text-gray-500">{label}</p>
      <div className="w-full  flex flex-wrap items-center gap-4 text-blue-500">
        <FontAwesomeIcon
          className="hidden md:block text-xl md:text-2xl lg:text-3xl xl:text-4xl"
          icon={icon}
        />
        <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold text-gray-800">
          {count}
        </p>
      </div>
    </div>
  );
};

export default StatisticsWidget;
