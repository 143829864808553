// import React from "react";

// const Hero = () => {
//   return (
//     <div className="bg-orange-500 py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
//         <h1 className="text-xl leading-8 text-white mb-4">
//           {
//             '"This Platform has made my life so much easier in creating events, sending emails, press releases and social media. We now have our contacts divided into separate mailing lists and we can track all our statistics for grants. Plus it offers lots of other features to keep me up to date."'
//           }
//         </h1>
//         <p className="text-white">
//           {"-- Patricia Frischer"}
//           <br />
//           {"Founder, San Diego Visual Arts Network"}
//           <br />
//           {"Co-Founder, North County Arts Network"}
//         </p>
//         <img
//           className="h-40 w-40 rounded-full mt-8 mx-auto"
//           src="https://smorgborg.imgix.net/assets/patricia.png?fit=max&w=1200"
//           alt="profile"
//         />
//       </div>
//     </div>
//   );
// };

// export default Hero;

import React from "react";

const Hero = () => {
  return (
    <div className="bg-orange-500 px-6 md:px-12 xl:px-16 py-4 md:py-10">
      <div className="w-full mx-auto p-6 text-center flex gap-8 flex-col md:flex-row items-center bg-[#efefef4b] rounded-lg">
        <div className="flex gap-2 flex-col justify-center items-center w-fit">
          <img
            className="h-24 sm:h-32 lg:h-40 w-24 sm:w-32 lg:w-40 rounded-full"
            src="https://smorgborg.imgix.net/assets/patricia.png?fit=max&w=1200"
            alt="profile"
          />

          <div className="space-y-0.5  text-white text-lg 2xl:text-xl">
            <p className="font-semibold ">Patricia Frischer</p>
            <p className="font-medium opacity-90">
              Founder, San Diego Visual Arts Network
            </p>
            <p className="opacity-80">Co-Founder, North County Arts Network</p>
          </div>
        </div>
        <p className="text-xl font-medium leading-relaxed 2xl:leading-10 text-white md:text-2xl 2xl:text-4xl flex-1 text-center">
          "This Platform has made my life so much easier in creating events,
          sending emails, press releases and social media. We now have our
          contacts divided into separate mailing lists and we can track all our
          statistics for grants. Plus it offers lots of other features to keep
          me up to date."
        </p>
      </div>
    </div>
  );
};

export default Hero;
